/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { GET_USER_METADATA_FAILURE, GET_USER_METADATA_REQUEST, GET_USER_METADATA_SUCCESS } from './Types'

export const getUserMetadataSuccess = (payload) => ({
	type: GET_USER_METADATA_SUCCESS,
	payload
})

export const getUserMetadataFailure = (payload) => ({
	type: GET_USER_METADATA_FAILURE,
	payload
})

// Get user metadata
export const getUserMetadataRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: GET_USER_METADATA_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: getUserMetadataSuccess,
				actionFailure: getUserMetadataFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/user/profile/${params.userId}/customfields`,
					...config.requestConfig
				}
			})
		}
