import * as Types from '../Types'

const initialState = {
	stores: [],
	selectedStore: {},
	latitude: null,
	longitude: null
}

export default (currentState = initialState, action) => {
	const { payload: { stores, selectedStore, longitude, latitude } = {} } = action

	switch (action.type) {
		case Types.STORES_UPDATE:
			return {
				...currentState,
				latitude: latitude || currentState.latitude,
				longitude: longitude || currentState.longitude,
				selectedStore: selectedStore || currentState.selectedStore,
				stores: stores || currentState.stores
			}

		case Types.STORES_CLEAR:
			return {
				...initialState
			}

		default:
			return currentState
	}
}
