/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { VALIDATE_MOBILE_NUMBER_FAILURE, VALIDATE_MOBILE_NUMBER_REQUEST, VALIDATE_MOBILE_NUMBER_SUCCESS } from './Types'

export const validateMobileNumberSuccess = (payload) => ({
	type: VALIDATE_MOBILE_NUMBER_SUCCESS,
	payload
})

export const validateMobileNumberFailure = (payload) => ({
	type: VALIDATE_MOBILE_NUMBER_FAILURE,
	payload
})

// Validates OTP sent for registration
export const validateMobileNumberRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: VALIDATE_MOBILE_NUMBER_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: validateMobileNumberSuccess,
				actionFailure: validateMobileNumberFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'put',
					url: '/user/register/otp/validate',
					data: {
						mobile: params.mobile,
						otp: params.otp
					},
					...config.requestConfig
				}
			})
		}
