import * as Types from '../Types'

const initialState = {
	dataReceipt: {},
	externalRewardAmount: null,
	rewardsBalance: null,
	trxId: null
}

export default (currentState = initialState, action) => {
	const { payload: { trxId, dataReceipt, rewardsBalance, externalRewardAmount } = {} } = action

	switch (action.type) {
		case Types.PAYMENT_UPDATE:
			return {
				...currentState,
				dataReceipt: dataReceipt || currentState.dataReceipt,
				externalRewardAmount: externalRewardAmount || currentState.externalRewardAmount,
				rewardsBalance: rewardsBalance || currentState.rewardsBalance,
				trxId: trxId || currentState.trxId
			}

		case Types.PAYMENT_RESET_WI_CODE:
			return {
				...currentState,
				trxId: null
			}

		case Types.PAYMENT_CLEAR:
			return {
				...initialState
			}

		default:
			return currentState
	}
}
