/* eslint-disable */
import React from 'react'

const Radio = ({ selected = false, imageUnselected, imageSelected, onPressIcon, radioLabel }) => (
	<div onClick={onPressIcon} style={{ display:'flex', width: '100%'}} className='radio-component'>
		<img
            className='radioIcon'
			alt="Radio"
			height={20}
			src={selected ? imageSelected : imageUnselected}
            width={20}
		/>
		<p className='gender-label' style={{marginLeft: 8, marginTop: 0}}>{radioLabel}</p>
	</div>
)

export default Radio


// div style={{width: '100%'}} className='radio-component'
