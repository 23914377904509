/*eslint-disable*/
import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import sha1 from 'sha1'

import Images from '../../../assets'
import { Header, PinState } from '../../../components'
import { ContainerActions } from '../../../state/'

const ForgotPinCreatePin = ({ authUpdate }) => {
    const navigate = useNavigate()

    const onSubmit = ({ pin }) => {
        const pinHashed = sha1(pin.target.value)

        authUpdate({
            pinCreate: pinHashed
        })
        navigate('/forgotPinConfirmPin')
    }

    return [
        <Header headerTitle="Create PIN" iconLeft={Images.arrowBackWhite} key={1} route="/register" isBackIcon={true}/>,
        <div key={2} style={{ paddingLeft: 40, paddingRight: 40 }}>
            <PinState state="CREATE_PIN" onEndReached={({ pin }) => {
                onSubmit({ pin: pin })
            }} />
        </div>
    ]
}

const mapDispatchToProps = (dispatch) => ({
    authUpdate: (payload) => dispatch(ContainerActions.authUpdate(payload))
})

const mapStateToProps = (state) => ({
    auth: state.container.auth
})


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPinCreatePin)
