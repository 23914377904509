/*eslint-disable*/
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Images from '../../assets'
import { Header, CouponItem, Spinner, EmptyListState, CouponDetails } from '../../components'
import { ContainerActions, NetworkActions } from '../../state'
import Poll from '../../utils/Poll'
import * as Styles from './InlineStyles'

const MyRewards = ({
	coupons: { coupons },
	couponsUpdate,
	getCampaignStoresRequest,
	getLoyaltyRewardsRequest,
	getPublicCouponCampaignsRequest,
	getUserPersonalisedVouchersRequest,
	viewLoyaltyRewards,
	viewUserpersonalisedVouchers,
	viewPublicCouponCampaigns
}) => {
	const rewardsCaption = 'Please select "Redeem My Rewards" to redeem upon check out.'
	const navigate = useNavigate()
	const [selectedCoupon, setSelectedCoupon] = useState({})
	const [isVisibleDetails, setIsVisibleDetails] = useState(false)
	const [latitude, setLatitude] = useState(null)
	const [longitude, setLongitude] = useState(null)

	useEffect(async () => {
		Poll.pollCancel()
		navigator.geolocation.getCurrentPosition(async (position) => {
			await setLatitude(position.coords.latitude)
			await setLongitude(position.coords.longitude)
		})
		await getAllUserCoupons()
	}, [])

	const getAllUserCoupons = async () => {
		try {
			const parallelApiResponse = [
				await getLoyaltyRewardsRequest(),
				await getPublicCouponCampaignsRequest({
					lon: longitude,
					lat: latitude
				}),
				await getUserPersonalisedVouchersRequest()
			]

			// console.log(parallelApiResponse)

			const [loyaltyRewards, publicCouponCampaigns, userPersonalisedVouchers] = parallelApiResponse
			const { couponVoucherList = [] } = loyaltyRewards
			const { couponCampaigns = [] } = publicCouponCampaigns
			const { coupons = [] } = userPersonalisedVouchers

			couponsUpdate({
				coupons: [...couponVoucherList, ...couponCampaigns, ...coupons]
			})
		} catch (e) {
			console.log(e)
		}
	}

	const getCampaignStores = async (id) => {
		let { channelId } = selectedCoupon
		if (channelId === undefined) channelId = 'PERSONALISED_CV_CHANNEL'
		try {
			const response = await getCampaignStoresRequest({
				channelId: channelId,
				campaignId: id || selectedCoupon.campaignId !== undefined ? selectedCoupon.campaignId : selectedCoupon.id,
				lon: longitude,
				lat: latitude
			})
			couponsUpdate({
				stores: response.stores
			})
			console.log({ response })
		} catch (e) {}
	}

	const renderCoupons = () => {
		let couponList

		if (!_.isEmpty(coupons)) {
			couponList = coupons.map((coupon, index) => {
				return (
					<CouponItem
						imageUrl={coupon.imageUrl}
						key={index}
						onClickCoupon={() => {
							setSelectedCoupon(coupon)
							setIsVisibleDetails(!isVisibleDetails)
						}}
					/>
				)
			})
		} else {
			return <EmptyListState caption="Tap your card to earn rewards and find your rewards here." title="NO ACTIVITY YET..." />
		}
		return <div style={{ paddingBottom: 80 }}>{couponList}</div>
	}

	return [
		<div key={1} style={Styles.background}>
			<Header alt="back" headerTitle="My Rewards" iconLeft={Images.arrowBackWhite} isBackIcon={true} route="/home" />
			{!_.isEmpty(coupons) && <div style={Styles.rewardsCaption}>{rewardsCaption}</div>}
			{!_.isEmpty(coupons) && (
				<div key={2} style={Styles.bottomButton} onClick={() => navigate('/scanMyRewards')}>
					<div style={Styles.buttonText}>Redeem My Rewards</div>
				</div>
			)}
			{viewLoyaltyRewards.fetching || viewUserpersonalisedVouchers.fetching || viewPublicCouponCampaigns.fetching ? (
				<Spinner />
			) : (
				renderCoupons()
			)}
			<CouponDetails
				isVisibleDetails={isVisibleDetails}
				item={selectedCoupon}
				onPressButton={() => setIsVisibleDetails(!isVisibleDetails)}
			/>
		</div>
	]
}

const mapStateToProps = (state) => ({
	coupons: state.container.coupons,
	viewCampaignStores: state.network.viewCampaignStores,
	viewLoyaltyRewards: state.network.viewLoyaltyRewards,
	viewUserpersonalisedVouchers: state.network.viewUserpersonalisedVouchers,
	viewPublicCouponCampaigns: state.network.viewPublicCouponCampaigns
})

const mapDispatchToProps = (dispatch) => ({
	couponsUpdate: (payload) => dispatch(ContainerActions.couponsUpdate(payload)),
	getCampaignStoresRequest: (payload) => dispatch(NetworkActions.viewCampaignStoresRequest(payload)),
	getLoyaltyRewardsRequest: (payload) => dispatch(NetworkActions.viewLoyaltyRewardsRequest(payload)),
	getPublicCouponCampaignsRequest: (payload) => dispatch(NetworkActions.viewPublicCouponCampaignsRequest(payload)),
	getUserPersonalisedVouchersRequest: (payload) => dispatch(NetworkActions.viewUserpersonalisedVouchersRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyRewards)
