/*eslint-disable*/
import React from 'react'
import { Routes, Route } from 'react-router-dom'

import * as Container from '../src/containers'
import { PrivateRoute } from './components'

import './App.css'

function App() {
	return (
		<div className="App">
			<Routes>
				<Route exact element={<Container.Walkthrough />} key="/" path="/" />
				<Route exact element={<Container.Login />} key="login" path="/login" />
				<Route exact element={<Container.ForgotPin />} key="forgotPin" path="/forgotPin" />
				<Route exact element={<Container.Register />} key="register" path="/register" />
				<Route exact element={<Container.CreatePin />} key="createPin" path="/createPin" />
				<Route exact element={<Container.ConfirmPin />} key="confirmPin" path="/confirmPin" />
				<Route exact element={<Container.ValidateMobile />} key="validateMobile" path="/validateMobile" />
				<Route exact element={<Container.ForgotPinCreatePin />} key="forgotPinCreatePin" path="/forgotPinCreatePin" />
				<Route
					exact
					element={<Container.ForgotPinConfirmPin />}
					key="forgotPinConfirmPin"
					path="/forgotPinConfirmPin"
				/>
				<Route
					exact
					element={<Container.ForgotPinValidateMobile />}
					key="forgotPinValidateMobile"
					path="/forgotPinValidateMobile"
				/>
				<Route element={<PrivateRoute />} key="private">
					<Route element={<Container.Home />} key="home" path="/home" />
					<Route element={<Container.Stores />} key="stores" path="/stores" />
					<Route element={<Container.ScanAndEarn />} key="scanAndEarn" path="/scanEarn" />
					<Route element={<Container.TransactionHistory />} key="tH" path="/transactionHistory" />
					<Route element={<Container.MyRewards />} key="myRewards" path="/myRewards" />
					<Route element={<Container.ScanMyRewards />} key="scanMyRewards" path="/scanMyRewards" />
					<Route element={<Container.ParticipatingStores />} key="participatingStores" path="/participatingStores" />
					<Route element={<Container.Inbox />} key="inbox" path="/inbox" />
					<Route element={<Container.Profile />} key="profile" path="/profile" />
				</Route>
			</Routes>
		</div>
	)
}

export default App
