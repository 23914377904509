import React from 'react'

const NewVoucher = ({ containerStyles }) => {
	return (
		<div
			style={{
				backgroundColor: '#DC1515',
				width: 12,
				height: 12,
				borderRadius: 6,
				...containerStyles
			}}
		>
			<div style={{ fontSize: 6, color: 'white', marginTop: 2, fontWeight: 'bold' }}>{1}</div>
		</div>
	)
}

export default NewVoucher
