import { RETRIEVE_WI_CODE_V2_FAILURE, RETRIEVE_WI_CODE_V2_REQUEST, RETRIEVE_WI_CODE_V2_SUCCESS } from './Types'

const INITIAL_STATE = {
	fetching: false,
	error: null,
	response: null,
	payload: null
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RETRIEVE_WI_CODE_V2_REQUEST:
			return {
				...state,
				fetching: true,
				error: null,
				response: null,
				payload: action.payload
			}

		case RETRIEVE_WI_CODE_V2_SUCCESS:
			return {
				...state,
				fetching: false,
				error: null,
				response: action.payload,
				payload: null
			}

		case RETRIEVE_WI_CODE_V2_FAILURE:
			return {
				...state,
				fetching: false,
				error: action.payload,
				response: null,
				payload: null
			}

		default:
			return state
	}
}
