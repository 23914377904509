import React from 'react'
import { connect } from 'react-redux'

import { ParticipatingStoresModal } from '../../components'

const ParticipatingStores = ({ coupons: { stores }, viewCampaignStores }) => (
	<ParticipatingStoresModal isFetching={viewCampaignStores.fetching} stores={stores} />
)

const mapStateToProps = (state) => ({
	coupons: state.container.coupons,
	viewCampaignStores: state.network.viewCampaignStores
})

export default connect(mapStateToProps)(ParticipatingStores)
