/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { GET_PROFILE_FAILURE, GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS } from './Types'

export const getProfileSuccess = (payload) => ({
	type: GET_PROFILE_SUCCESS,
	payload
})

export const getProfileFailure = (payload) => ({
	type: GET_PROFILE_FAILURE,
	payload
})

// Gets the user details / profile
export const getProfileRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: GET_PROFILE_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: getProfileSuccess,
				actionFailure: getProfileFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: '/user/profile',
					headers: {
						'Authorization-X': getState().persisted.user.authX,
						'Access-Control-Allow-Origin' : '*',
						'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
					},
					...config.requestConfig
				}
			})
		}
