/*eslint-disable*/
import React from 'react'
import { connect } from 'react-redux'

import Images from '../../assets'
import { PersistedActions } from '../../state'

const DrawerItem = ({ title, onClickItem, isLogout }) => (
	<div
		style={{
			height: 50,
			borderBottomStyle: 'solid',
			borderBottomWidth: 0.5,
			borderBottomColor: isLogout ? 'transparent' : '#4B85C4',
			fontSize: 14,
			color: 'white',
			textAlign: 'left',
			paddingLeft: 16,
			marginLeft: 27
		}}
		onClick={onClickItem}
	>
		<div style={{ paddingTop: 16 }}>{title}</div>
	</div>
)

const DrawerTab = ({ firstName, lastName, currentBalance, totalBalance, links, userUpdate }) => {
	const pointCaption = 'Fill balance:'

	return (
		<div
			className="drawerNav"
			style={{
				width: 'auto',
				height: '100%',
				position: 'fixed',
				zIndex: 1,
				top: 0,
				right: 0,
				backgroundColor: '#231F20',
				overflowX: 'hidden',
				alignItems: 'center'
			}}
			onClick={() =>
				userUpdate({
					showDrawer: false
				})
			}
		>
			<img
				alt="close"
				height={10}
				src={Images.iconCloseWhite}
				style={{ position: 'absolute', left: 0, margin: 8 }}
				width={10}
				onClick={() =>
					userUpdate({
						showDrawer: false
					})
				}
			/>
			<img alt="logo" className="logo" height={120} src={Images.homeLogo} width={266} />
			<p style={{ color: 'white', fontSize: 20, fontWeight: '700' }}>{`${firstName} ${lastName}`}</p>
			<div style={{color: 'white', fontSize: 16}}>
				{pointCaption}
			</div>
			<div style={{color: 'white', fontSize: 16}}>
				{` ${currentBalance || 0}/${totalBalance} Visits`}
			</div>
			{links.map((link, index) => (
				<DrawerItem
					isLogout={link.isLogout}
					key={index}
					title={link.title}
					onClickItem={() =>
						link.isLogout ? link.onClick() : window.open(`https://my-diesel.co.za/${link.route}`)
					}
				/>
			))}
		</div>
	)
}

const mapStateToProps = (state) => ({
	loyaltyBalance: state.persisted.user.loyaltyBalance
})

const mapDispatchToProps = (dispatch) => ({
	userUpdate: (payload) => dispatch(PersistedActions.userUpdate(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawerTab)
