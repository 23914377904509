/* eslint-disable*/
import React from 'react'
import {Link} from 'react-router-dom'

import Images from '../../assets'
import { Button } from '../../components'

const title1 = 'Scan, Earn & Redeem Rewards'

const caption =
	'Earn tier points & rand value loyalty on each transaction made with the program. Redeem your loyalty balance whenever you like.'

const Walkthrough = () => (
	<div className='background' style={{height: '100vh'}}>
		<div key={1}>
			<img alt="logo" className="logo" src={Images.homeLogo} width={266} height={120}/>
		</div>
		<div key={2} style={{paddingLeft: 46, paddingRight: 46}}>
			<img alt='walkthrough1' src={Images.walkthroughImage2} style={{height: 183, width: 142, marginTop: 80}}/>
			<h2 style={{marginTop: 69, color: 'white'}}>{title1}</h2>
			<p className='landing-caption'>{caption}</p>
		</div>
		<div
			className="walkthrough-buttons"
			key={3}
			style={{ justifyContent: 'center', alignItems: 'center'}}
		>
			<div style={{ marginRight: 6 }}>
				<Link to='/register'><Button buttonStyles={{ marginLeft: 6 }} buttonTitle="Register" buttonWidth={154} /></Link>
			</div>
			<div style={{ marginLeft: 6 }}>
				<Link to='/login'><Button buttonStyles={{ marginLeft: 6 }} buttonTitle="Log In" buttonWidth={154} /></Link>
			</div>
		</div>
	</div>
)



export default Walkthrough
