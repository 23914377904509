/*eslint-disable*/
import React from 'react'
import moment from 'moment'

import Button from '../button'
import * as Styles from '../detailsModal/InlineStyles'

const CouponDetails = ({ item, onPressButton, isVisibleDetails }) => (
    isVisibleDetails && (<div className="transaction-details-modal" onClick={onPressButton}>
		<div style={Styles.detailsContainer}>
			<div style={{ fontSize: 20, fontWeight: '600', color: '#525252', marginBottom: 11, marginTop: 32 }}>{item.name}</div>
			<div style={{ fontSize: 13, fontWeight: '400', color: '#525252', marginBottom: 11, textAlign: 'center' }}>
				Voucher Expiry Date: {moment(item.expiryDate).format('DD/MM/YYYY')}
			</div>
			<div style={{ fontSize: 13, fontWeight: '400', color: '#525252'}}>
                {item.description}
            </div>
            <div style={{marginBottom: 31}}>
				<Button isForModal buttonTitle="Okay" className="alert-button" buttonClassName='alert-button-text' onPress={onPressButton} />
			</div>
		</div>
	</div>)
)

export default CouponDetails
