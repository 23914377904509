/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { RETRIEVE_WI_CODE_V2_FAILURE, RETRIEVE_WI_CODE_V2_REQUEST, RETRIEVE_WI_CODE_V2_SUCCESS } from './Types'

export const retrieveWiCodeV2Success = (payload) => ({
	type: RETRIEVE_WI_CODE_V2_SUCCESS,
	payload
})

export const retrieveWiCodeV2Failure = (payload) => ({
	type: RETRIEVE_WI_CODE_V2_FAILURE,
	payload
})

// Get wicode V2
export const retrieveWiCodeV2Request =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload
			dispatch({
				type: RETRIEVE_WI_CODE_V2_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: retrieveWiCodeV2Success,
				actionFailure: retrieveWiCodeV2Failure,
				...config,
				dispatch,
				requestConfig: {
					method: 'post',
					url: '/transactions/token/v2',
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					data: {
						tenderType: params.tenderType,
					},
					...config.requestConfig
				}
			})
		}
