/*eslint-disable*/
import React from 'react'

import './Styles.css'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Images from '../../assets/'
import { Button, Header, WiCode } from '../../components'

const ScanAndEarn = ({ profile }) => {
	const navigate = useNavigate()

	return (
		<div className="scan-earn-background" style={{ height: '100vh' }}>
			<Header alt="back" headerTitle="Scan & Earn" iconLeft={Images.arrowBackWhite} route="/home" isBackIcon={true}/>
			<WiCode qrString={profile.pan} title="Scan My Card" />
			<div style={{paddingBottom: 80}}>
				<Button buttonTitle="Back to Home" onPress={() => navigate('/home')} buttonStyles={{marginTop: 20}}/>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	profile: state.persisted.user.profile
})

export default connect(mapStateToProps)(ScanAndEarn)
