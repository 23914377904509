/* eslint-disable */
import React from 'react'

const termsAndConditions = (
	<div>
		I agree to the{' '}
		<b
			style={{ color: '#64A5E8' }}
			onClick={() => window.open('https://my-diesel.co.za/wp-content/uploads/2022/09/MyDIESEL_Ts_and_Cs.pdf')}
		>
			Terms & Conditions
		</b>{' '}
		*
	</div>
)

const Checkbox = ({
	onPressIcon,
	selected,
	imageSelected,
	imageUnselected,
	checkboxLabel,
	isForTermsAndConditions
}) => (
	<div className="radio-component" style={{ display: 'flex', width: '100%' }} onClick={onPressIcon}>
		<img alt="Checkbox" className="radioIcon" height={20} src={selected ? imageSelected : imageUnselected} width={20} />
		<p className="gender-label" style={{ marginLeft: 8, marginTop: 0, width: '100%', fontSize: 10 }}>
			{isForTermsAndConditions ? termsAndConditions : checkboxLabel}
		</p>
	</div>
)

export default Checkbox
