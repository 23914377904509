/*eslint-disable*/
import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import sha1 from 'sha1'

import Images from '../../../assets'
import { Button, Header, Input, MobileInput, AuthError } from '../../../components'
import { GeneralActions, NetworkActions, PersistedActions } from '../../../state'

const Login = ({ auth, authError, authErrorShow, errorShow, getProfileRequest, userUpdate, loginRequest, loginState, user }) => {

	const [formData, setFormData] = React.useState({
		mobile: '',
		pin: ''
	})

	const [isMobileFormActive, setIsMobileFormActive] = React.useState(false)


	const navigate = useNavigate()

	const mobilePrefix = '+27'

	const { mobile, pin } = formData

	const pinHashed = sha1(pin)

	const formattedMobileNumber = `${mobilePrefix}${mobile}`

	const onChange = (e) =>
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})

	const updateUserProfile = ({ profile }) => {
		userUpdate({ profile: profile })
	}

	const onLoginSuccess = async ({ response: { authX } }) => {
		userUpdate({
			authX: authX,
			isAuthenticated: true
		})

		try {
			const response = await getProfileRequest()
			updateUserProfile({ profile: response.user })
		} catch (e) {
			errorShow({
				errorTitle: e.messageTitle,
				errorBody: e.messageBody
			})
		}
	}

	const login = async () => {
		try {
			const response = await loginRequest({
				mobile: formattedMobileNumber,
				pin: pinHashed,
				config: {
					canReturnAuthX: true
				}
			})

			await onLoginSuccess({ response: response })
			navigate('/home')
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	return [
		<Header headerTitle="Sign in" iconHeight={10} iconLeft={Images.iconCloseWhite} iconWidth={10} key={1} route="/" />,
		<img alt="logo" className="logo" src={Images.homeLogo} width={266} height={120} key={2}/>,
		<div style={{ marginTop: 50, marginLeft: 56, marginRight: 56 }}>
			<MobileInput
				key='mobile'
				isMobileFormActive={isMobileFormActive}
				inputId="mobile"
				inputLabel="Mobile Number *"
				inputName="mobile"
				inputType="text"
				prefix={mobilePrefix}
				value={mobile}
				onClick={() => setIsMobileFormActive(!isMobileFormActive)}
				onChange={(text) => {
					let mobileNum = text.target.value
					let zero = mobileNum.charAt(0)
					if(zero === '0'){
						mobileNum = mobileNum.slice(1)
					}
					let parseMobileNumber = mobileNum.slice(0, 9)
					setFormData({
						...formData,
						mobile: parseMobileNumber
					})
				}}
			/>
		</div>,
		<div style={{ marginLeft: 56, marginRight: 56 }}>
			<Input
				canShowError={authError.isErrorVisible}
				key='pin'
				inputId="pin"
				inputLabel="PIN *"
				inputName="pin"
				inputType="password"
				value={pin}
				errorTitle={authError.authErrorTitle}
				errorBody={authError.authErrorBody}
				onChange={(text) => {
					text.target.value = text.target.value.slice(0, 4)
					onChange(text)}
			}
			/>
		</div>,
		<div key={4}>
			<Button fetching={loginState.fetching} buttonTitle="Proceed" onPress={async () => await login()} />
			<div className="forgot-pin-button" onClick={() => navigate('/forgotPin')} style={{marginTop: 25}}>
				<div style={{ color: 'white', fontWeight: 'bold' }}>Reset PIN</div>
			</div>
		</div>,
	]
}

const mapStateToProps = (state) => ({
	auth: state.container.auth,
	authError: state.general.authError,
	loginState: state.network.login,
	user: state.persisted.user
})

const mapDispatchToProps = (dispatch) => ({
	authErrorShow: (payload) => dispatch(GeneralActions.authErrorShow(payload)),
	errorShow: (payload) => dispatch(GeneralActions.errorShow(payload)),
	getProfileRequest: (payload) => dispatch(NetworkActions.getProfileRequest(payload)),
	loginRequest: (payload) => dispatch(NetworkActions.loginRequest(payload)),
	userUpdate: (payload) => dispatch(PersistedActions.userUpdate(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
