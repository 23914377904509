import * as Types from '../Types'

export const paymentUpdate = (payload) => ({
	type: Types.PAYMENT_UPDATE,
	payload
})

export const paymentResetWiCode = (payload) => ({
	type: Types.PAYMENT_RESET_WI_CODE,
	payload
})

export const paymentClear = (payload) => ({
	type: Types.PAYMENT_CLEAR,
	payload
})
