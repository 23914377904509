const GOOGLE_API_KEY = 'AIzaSyDbOwkfElYyaOo8fJ2-JQ6A1CsKWWdospQ'

const environments = {
	integration: {
		baseUrl: 'https://idiesel.int.rwstack.wigroup.io/api/templatemobi/v1/rest/',
		contentfulVspId: 20109,
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`
	},
	production: {
		baseUrl: 'https://idiesel.prod.rwstack.wigroup.io/api/templatemydieselmobi/v1/rest',
		contentfulVspId: 20109,
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`
	}
}

export default environments.integration
