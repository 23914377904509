import React from 'react'

const Badge = ({ unreadMessages, containerStyles }) => (
	<div
		style={{
			backgroundColor: '#DC1515',
			width: 12,
			height: 12,
			borderRadius: 6,
			position: 'absolute',
			marginLeft: 20,
			marginBottom: 4,
			...containerStyles
		}}
	>
		<div style={{ fontSize: 6, color: 'white', marginTop: 2, fontWeight: 'bold' }}>{unreadMessages}</div>
	</div>
)

export default Badge
