import * as Types from '../Types'

export const authUpdate = (payload) => ({
	type: Types.AUTH_UPDATE,
	payload
})

export const authClear = (payload) => ({
	type: Types.AUTH_CLEAR,
	payload
})
