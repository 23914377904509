import { combineReducers } from 'redux'

import calculateUserBalance from './calculateUserBalance/Reducers'
import createPin from './createPin/Reducers'
import findSubdivisions from './findSubdivisions/Reducers'
import forgotPin from './forgotPin/Reducers'
import getArticles from './getArticles/Reducers'
import getMessage from './getMessage/Reducers'
import getMessages from './getMessages/Reducers'
import getProfile from './getProfile/Reducers'
import getStore from './getStore/Reducers'
import getStores from './getStores/Reducers'
import getUserMetadata from './getUserMetadata/Reducers'
import login from './login/Reducers'
import putUserMetadata from './putUserMetadata/Reducers'
import registerV2 from './registerV2/Reducers'
import resendOtp from './resendOtp/Reducers'
import resetPin from './resetPin/Reducers'
import retrieveUserTransaction from './retrieveUserTransaction/Reducers'
import retrieveWiCodeV2 from './retrieveWicodeV2/Reducers'
import update from './updateUser/Reducers'
import validateMobile from './validateMobileNumber/Reducers'
import viewCampaignStores from './viewCampaignStores/Reducers'
import viewLoyaltyCampaigns from './viewLoyaltyCampaigns/Reducers'
import viewLoyaltyRewards from './viewLoyaltyRewards/Reducers'
import viewPublicCouponCampaigns from './viewPublicCouponCampaigns/Reducers'
import viewTransactionHistory from './viewTransactionHistory/Reducers'
import viewUserpersonalisedVouchers from './viewUserPersonalisedVouchers/Reducers'

export default combineReducers({
	calculateUserBalance,
	createPin,
	forgotPin,
	findSubdivisions,
	getArticles,
	getProfile,
	getMessage,
	getMessages,
	getStore,
	getStores,
	getUserMetadata,
	login,
	putUserMetadata,
	registerV2,
	resendOtp,
	resetPin,
	retrieveUserTransaction,
	retrieveWiCodeV2,
	update,
	validateMobile,
	viewCampaignStores,
	viewLoyaltyCampaigns,
	viewLoyaltyRewards,
	viewPublicCouponCampaigns,
	viewTransactionHistory,
	viewUserpersonalisedVouchers
})
