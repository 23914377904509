/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { GET_MESSAGES_FAILURE, GET_MESSAGES_REQUEST, GET_MESSAGES_SUCCESS } from './Types'

export const getMessagesSuccess = (payload) => ({
	type: GET_MESSAGES_SUCCESS,
	payload
})

export const getMessagesFailure = (payload) => ({
	type: GET_MESSAGES_FAILURE,
	payload
})

// Get list of inbox messages.
export const getMessagesRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: GET_MESSAGES_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.pageOffset) queryString += `pageOffset=${params.pageOffset}&`
			if (params.pageSize) queryString += `pageSize=${params.pageSize}&`
			queryString = queryString.slice(0, -1)

			return handleRequest({
				actionSuccess: getMessagesSuccess,
				actionFailure: getMessagesFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/inbox/messages${queryString}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					...config.requestConfig
				}
			})
		}
