/*eslint-disable*/
import _ from 'lodash'
import React from 'react'
import Images from '../../assets'

const Article = ({ data, isVisible, onClickBack }) => {
	let textKey = 0
	const returnBodyText = ({ bold, italic, str, tag, underline }) => {
		// Get text between tags
		if (str.substr(0, tag.length) === tag) {
			const textStart = str.indexOf('>') + 1
			const text = str.substr(textStart)
			textKey += 1

			return {
				bold,
				italic,
				key: textKey,
				text,
				underline
			}
		}

		// Get remaining text after closing tag
		const textStart = str.indexOf('>') + 1
		const text = str.substr(textStart)
		textKey += 1

		return {
			key: textKey,
			text
		}
	}

	const parseText = ({ textToParse }) => {
		const strArr = _.split(textToParse, '<')
		const body = []

		strArr.map((str) => {
			const isBold = str.includes('strong>') || str.includes('/strong>') || str.includes('b>') || str.includes('/b>')
			const isItalic = str.includes('i>') || str.includes('/i>') || str.includes('em>') || str.includes('/em>')
			const isLink = str.includes('href=') || str.includes('/a>')
			const isUnderline = str.includes('u>') || str.includes('/u>')

			if (isLink) {
				if (str.includes('href=')) {
					// Get link
					const linkStart = str.indexOf('href=') + 6
					const linkEnd = str.substr(linkStart).indexOf('"')
					let link = str.substr(linkStart, linkEnd)
					if (link.substr(0, 4) !== 'http') link = `http://${link}`

					// Get link description
					const textStart = str.indexOf('>') + 1
					const text = str.substr(textStart)

					textKey += 1
					body.push({
						key: textKey,
						link,
						text
					})
				} else {
					// Get remaining text
					body.push(
						returnBodyText({
							str,
							tag: '/a>'
						})
					)
				}
			} else if (isBold) {
				const tag = str.includes('strong>') ? 'strong>' : 'b>'

				body.push(
					returnBodyText({
						bold: true,
						str,
						tag
					})
				)
			} else if (isItalic) {
				const tag = str.includes('i>') ? 'i>' : 'em>'

				body.push(
					returnBodyText({
						italic: true,
						str,
						tag
					})
				)
			} else if (isUnderline) {
				body.push(
					returnBodyText({
						str,
						tag: 'u>',
						underline: true
					})
				)
			} else {
				textKey += 1
				body.push({
					bold: false,
					italic: false,
					key: textKey,
					link: null,
					text: str,
					underline: false
				})
			}

			return null
		})

		return body
	}

	const renderText = ({ body }) => {
		const parsedText = parseText({ textToParse: body })

		return (
			<div style={{fontSize: 14}}>
				{parsedText.map((element) => {
					const { bold, italic, key, link, text, underline } = element

					if (link) {
						return (
							<div
								key={key}
								style={{ color: '#64A5E8' }}
								// onClick={() => onPressLink({ url: link })}
							>
								{text}
							</div>
						)
					}
					if (bold) {
						return <div>{text}</div>
					}
					if (italic) {
						return (
							<div key={key} style={{ fontStyle: 'italic' }}>
								{text}
							</div>
						)
					}
					if (underline) {
						return (
							<div key={key} style={{ textDecoration: 'underline' }}>
								{text}
							</div>
						)
					}

					return <div key={key}>{text}</div>
				})}
			</div>
		)
	}

	const renderImage = ({ image }) => {
		const {
			fields: { description }
		} = image
		const source = `https:${image.fields.file.url}`

		return (
			<img
				alt="image"
				src={source}
				height={150}
				width={300}
				style={{padding: 8}}
			/>
		)
	}

	if (_.isEmpty(data)) return null

	const imageHeaderSource = `https:${data.fields.headerImage.fields.file.url}`

	return (
		isVisible && (
			<div className="transaction-details-modal" style={{overflowY: "auto"}}>
				<div style={{width: '100%'}}>
					<div onClick={() => onClickBack()}  style={{position: 'absolute', top: 20,alignItems: 'flex-start', height: 50, width: 50}}>
						<img alt='back' src={Images.arrowBackWhite} width={5.87} height={10}/>
					</div>
					<img alt="header-image" src={imageHeaderSource} width='100%' height={223}/>
					<div style={{backgroundColor: '#231F20', textAlign: "left", fontSize: 20, fontWeight: 'bold', color: "white", padding: 21,width: 'auto'}}>
						{data.fields.header && <div>{data.fields.header}</div>}
						{data.fields.subHeader && <div>{data.fields.subHeader}</div>}
					</div>
					<div style={{backgroundColor: '#231F20', color: "white", paddingLeft: 21, paddingRight: 21, paddingBottom: 80, textAlign: "left", height: '100vh'}}>
						{data.fields.bodyCopy1 && renderText({ body: data.fields.bodyCopy1 })}
						{data.fields.bodyImage1 && renderImage({ image: data.fields.bodyImage1 })}
						{data.fields.bodyCopy2 && renderText({ body: data.fields.bodyCopy2 })}
						{data.fields.bodyImage2 && renderImage({ image: data.fields.bodyImage2 })}
						{data.fields.bodyCopy3 && renderText({ body: data.fields.bodyCopy3 })}
						{data.fields.bodyImage3 && renderImage({ image: data.fields.bodyImage3 })}
					</div>
				</div>
			</div>
		)
	)
}

export default Article
