/*eslint-disable*/
import React from 'react'

import './Styles.css'
import Images from '../../assets/'

const StoreListItem = ({ storeName, distance, onClickItem, isParticipatingStore = false }) => (
	<div className="item-container" onClick={onClickItem}>
		<div className='text-container'>
			<p className="store-name">
				{storeName}
			</p>
			{!isParticipatingStore && <p className="distance">{`${distance}km`}</p>}
		</div>
		<img alt="icon" src={Images.arrowForward} height={10} width={5.87} style={{marginTop: 23}}/>
	</div>
)

export default StoreListItem
