import React from 'react'

import * as Styles from './InlineStyles'

const InboxItem = ({ isRead, messageTitle, body, date, onClickItem }) => {
	return (
		<div style={Styles.itemContainer} onClick={onClickItem}>
			<div style={{ marginTop: 16 }}>
				<div
					style={{
						color: 'white',
						textAlign: 'left',
						width: '100%',
						marginBottom: 8,
						fontSize: 14,
						fontWeight: isRead ? '400' : '700'
					}}
				>
					{messageTitle}
				</div>
				<div style={Styles.body}>{body}</div>
			</div>
			<div style={{ color: 'white', marginTop: 16, fontSize: 14, fontWeight: isRead ? '400' : '700' }}>{date}</div>
		</div>
	)
}

export default InboxItem
