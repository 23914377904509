/*eslint-disable*/
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps'
import { connect } from 'react-redux'

import Images from '../../assets'
import { Header, Toggle, StoreListItem, Spinner, StoreDetails, StoreDetailsMap } from '../../components'
import Config from '../../config/AppConfiguration'
import { ContainerActions, NetworkActions } from '../../state'
import { convertDistance } from '../../utils'
import './Styles.css'
import MapStyle from './MapStyle.json'

const Stores = ({ getStoreRequest, getStoresRequest, selectedStore, storesUpdate, fetchStores }) => {
	const [isListView, setIsListView] = useState(true)

	const [isMapView, setIsMapView] = useState(false)

	const [stores, setStores] = useState([])

	const [searchTerm, setIsSearchTerm] = useState(null)

	const [isStoreDetailsVisible, setIsStoreDetailsVisible] = useState(false)

	const [selectedStoreOnMap, setSelectedStoreOnMap] = useState({})

	const keysToSearch = ['storeName', 'address']

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(async (position) => {
			await getStores({ latitude: position.coords.latitude, longitude: position.coords.longitude })
		})
	}, [])

	const onSearch = (e) => setIsSearchTerm(e.target.value)

	const filterData = () => {
		if (searchTerm) {
			const filteredData = []

			stores.filter((item) =>
				keysToSearch.map((keyToSearch) => {
					if (item[keyToSearch].toLowerCase().includes(searchTerm.toLowerCase())) filteredData.push(item)
					return null
				})
			)

			return _.uniq(filteredData)
		}

		return stores
	}

	const getStores = async ({ latitude, longitude }) => {
		try {
			const response = await getStoresRequest({
				pageNumber: 1,
				lat: latitude,
				lon: longitude
			})
			await storesUpdate({
				stores: response.stores
			})
			setStores(response.stores)
		} catch (e) {}
	}

	const getStore = async (storeId) => {
		try {
			const { store = {} } = await getStoreRequest({ storeId })

			storesUpdate({
				selectedStore: !_.isEmpty(store) ? store : {}
			})
		} catch (e) {
			console.log({ e })
		}
	}

	const renderStoresList = () => {
		let storeList
		if (!_.isEmpty(stores)) {
			storeList = filterData().map((store, index) => (
				<StoreListItem
					distance={convertDistance(store.distance)}
					key={store.id || index}
					storeName={store.storeName}
					onClickItem={async () => {
						await getStore(store.storeId)
						setIsStoreDetailsVisible(true)
					}}
				/>
			))
		}

		return storeList
	}

	const renderMapStoreDetails = () => {
		let storeDetailsList
		if (!_.isEmpty(stores)) {
			storeDetailsList = stores.map((store, index) => (
				<StoreDetailsMap
					address={store.address}
					distance={convertDistance(store.distance)}
					key={store.storeId || index}
					phone={store.phone}
					storeName={store.storeName}
					onClickTile={async () => {
						await getStore(store.storeId)
						setIsStoreDetailsVisible(true)
					}}
				/>
			))
		}

		return (
			<div className="scroll" style={{ display: 'flex', backgroundColor: 'white', paddingBottom: 60 }}>
				{storeDetailsList}
			</div>
		)
	}

	const selectStoreOnMap = (storeId) => {
		let storeIndex

		if (storeId) storeIndex = stores.findIndex((store) => store.storeId === storeId)

		if (storeIndex !== null || storeIndex !== -1) {
			setSelectedStoreOnMap(stores[storeIndex])
		}
	}

	const Map = withScriptjs(
		withGoogleMap(() => (
			<GoogleMap
				defaultCenter={{ lat: stores[0].latitude, lng: stores[0].longitude }}
				defaultOptions={{ styles: MapStyle }}
				defaultZoom={14}
			>
				{stores.map((store, index) => (
					<Marker
						icon={selectedStoreOnMap ? Images.locationActive : Images.location}
						key={store.storeId || index}
						position={{ lat: store.latitude, lng: store.longitude }}
						onClick={() => selectStoreOnMap(store.storeId)}
					/>
				))}
			</GoogleMap>
		))
	)

	return [
		<div key={1} style={{ backgroundColor: '#231F20' }}>
			<Header headerTitle="Find Stores" iconLeft={null} iconRight={Images.menu} route="/home" />
			<Toggle
				isLeftActive={isListView}
				isRightActive={isMapView}
				leftTitle="List"
				rightTitle="Map"
				onClickLeft={() => {
					setIsListView(true)
					setIsMapView(false)
				}}
				onClickRight={() => {
					setIsMapView(true)
					setIsListView(false)
				}}
			/>
			{isListView && (
				<>
					<div className="search-container">
						<input
							className="search-bar"
							name="search"
							placeholder="Search for store"
							type="text"
							onChange={(text) => onSearch(text)}
						/>
						<button type="submit" onSubmit={() => filterData()}>
							<i className="fa fa-search" />
						</button>
					</div>
					{fetchStores.fetching ? <Spinner /> : renderStoresList()}
				</>
			)}
			{isMapView && [
				<Map
					isMarkerShown
					containerElement={<div style={{ height: '415px' }} />}
					googleMapURL={Config.state.googleMapURL}
					loadingElement={<div style={{ height: '415px' }} />}
					mapElement={<div style={{ height: '100%' }} />}
				/>,
				renderMapStoreDetails()
			]}
			<StoreDetails
				address={selectedStore.address}
				distance={selectedStore.distance}
				isDetailsVisible={isStoreDetailsVisible}
				phone={selectedStore.phone}
				storeName={selectedStore.storeName}
				storeTradingTimes={selectedStore.storeTradingTimes}
				onClickModal={() => setIsStoreDetailsVisible(false)}
			/>
		</div>
	]
}

const mapStateToProps = (state) => ({
	fetchStores: state.network.getStores,
	latitude: state.container.stores.latitude,
	longitude: state.container.stores.longitude,
	selectedStore: state.container.stores.selectedStore,
	storesList: state.container.stores
})

const mapDispatchToProps = (dispatch) => ({
	getStoreRequest: (payload) => dispatch(NetworkActions.getStoreRequest(payload)),
	getStoresRequest: (payload) => dispatch(NetworkActions.getStoresRequest(payload)),
	storesUpdate: (payload) => dispatch(ContainerActions.storesUpdate(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Stores)
