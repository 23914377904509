/*eslint-disable*/
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Images from '../../assets'
import {
	Button,
	Header,
	Input,
	MobileInput,
	Radio,
	UserUpdatedModal,
	NotificationsBanner,
	AuthError
} from '../../components'
import { ContainerActions, GeneralActions, NetworkActions, PersistedActions } from '../../state'

const Profile = ({
	auth: { subdivisions },
	authError,
	authErrorShow,
	authUpdate,
	findSubdivisionRequest,
	getProfileRequest,
	getUserMetadataRequest,
	putUserMetadataRequest,
	user: { profile },
	userUpdate,
	updateRequest
}) => {
	const navigate = useNavigate()

	const [isDetailsUpdatedModal, setIsDetailsUpdatedModal] = useState(false)
	const [isGenderMale, setIsGenderMale] = React.useState(profile.gender === 'M')
	const [isGenderFemale, setIsGenderFemale] = React.useState(profile.gender === 'F')
	const [isGenderNotSpecified, setIsGenderNotSpecified] = React.useState(profile.gender === null)
	const [isNotificationsOptedIn, setIsNotificationsOptedIn] = React.useState(profile.notificationsOptedIn)
	const [isRegionsDropDown, setIsRegionsDropDown] = React.useState(false)
	const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = React.useState(true)

	useEffect(async () => {
		await findSubdivisions()
		await getUserMetadata()
	}, [])

	let formattedDate = null

	let userMetadata = {}

	const [formData, setFormData] = React.useState({
		firstName: profile.firstName,
		surname: profile.lastName,
		mobile: profile.mobile,
		email: profile.email,
		dateOfBirth: profile.dateOfBirth,
		province: profile.homeRegion,
		cardNo: profile.pan,
		gender: profile.gender,
		company: ''
	})

	const { firstName, surname, mobile, email, dateOfBirth, province, cardNo, gender, company } = formData

	const findSubdivisions = async () => {
		try {
			const response = await findSubdivisionRequest()
			authUpdate({
				subdivisions: response.subdivisions
			})
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	const getUserMetadata = async () => {
		try {
			const response = await getUserMetadataRequest({
				userId: profile.id
			})
			onGetUserMetadataRequestSuccess({ metadata: response.metadata })
		} catch (e) {}
	}

	const onGetUserMetadataRequestSuccess = ({ metadata }) => {
		const userMetadata = Array.from(metadata)
		const parsedMetadata = {}

		userMetadata.forEach((metaValue) => {
			const { name, value } = metaValue
			if (name === 'company') {
				parsedMetadata[name] = value
				setFormData({
					...formData,
					company: value
				})
				userUpdate({
					company: value
				})
			}
		})
	}

	const updateProfile = async () => {
		if (dateOfBirth) {
			const [year, month, day] = dateOfBirth.split('/')
			formattedDate = `${year}-${month}-${day}`
		}

		if (company) {
			userMetadata = {
				company: company
			}
		}

		try {
			const response = await updateRequest({
				firstName: firstName,
				lastName: surname,
				mobile: mobile,
				email: email,
				dateOfBirth: formattedDate,
				homeRegion: province,
				pan: cardNo,
				gender: !gender || gender === 'NS' ? null : gender,
				notificationsOptedIn: isNotificationsOptedIn,

				config: {
					canReturnAuthX: true
				}
			})

			putUserMetadata({ metadata: userMetadata, userId: profile.id })

			onUpdateProfileRequestSuccess({ response: response, mobileNew: mobile })
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	const putUserMetadata = ({ metadata, userId }) => {
		if (!_.isEmpty(metadata)) {
			// Parse the metadata object
			const parsedMetadata = []
			Object.keys(metadata).forEach((metaValue) => {
				parsedMetadata.push({
					name: metaValue,
					value: metadata[metaValue]
				})
			})

			putUserMetadataRequest({
				userId: userId,
				metadata: parsedMetadata,
				config: {
					canReject: false
				}
			})
		}
	}

	const onUpdateProfileRequestSuccess = async ({ response, mobileNew }) => {
		if (mobileNew !== profile.mobile) {
			authUpdate({
				isUserUpdatingDetails: true,
				registerDetails: {
					mobile: mobileNew
				}
			})
			navigate('/validateMobile')
		} else {
			await onUpdateProfileSuccess({ response: response })
		}
	}

	const onUpdateProfileSuccess = async ({ response: { authX } }) => {
		userUpdate({ authX: authX })

		await getUpdatedProfile()
	}

	const getUpdatedProfile = async () => {
		try {
			const response = await getProfileRequest()

			userUpdate({
				profile: response.user
			})
			setIsDetailsUpdatedModal(true)
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	const onChange = (e) => {
		setIsUpdateButtonDisabled(false)
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	return [
		<div key={1} style={{ backgroundColor: '#231F20', height: '100vh' }}>
			<Header headerTitle="Profile" iconLeft={null} iconRight={Images.menu} route="/home" />
			<div style={{ marginLeft: 56, marginRight: 56 }}>
				<Input
					required
					inputId="firstName"
					inputLabel="First Name"
					inputName="firstName"
					inputType="text"
					value={firstName}
					onChange={(text) => onChange(text)}
				/>
				<Input
					inputId="surname"
					inputLabel="Surname"
					inputName="surname"
					inputType="text"
					value={surname}
					onChange={(text) => onChange(text)}
				/>
				<MobileInput
					isForProfile
					inputId="mobile"
					inputLabel="Mobile"
					inputName="mobile"
					inputType="text"
					isMobileFormActive={true}
					prefix={null}
					value={mobile}
					onChange={(text) => onChange(text)}
				/>
				<Input
					inputId="email"
					inputLabel="Email"
					inputName="email"
					inputType="email"
					value={email}
					onChange={(text) => onChange(text)}
				/>
				<Input
					inputId="dateOfBirth"
					inputLabel="Date of Birth"
					inputName="dateOfBirth"
					inputType="text"
					placeholder="YYYY/MM/DD"
					value={dateOfBirth}
					onChange={(text) => onChange(text)}
					onFocus={(e) => e.target.type = 'date' }
					onBlur={(e) => e.target.type = 'text' }
				/>
				<Input
					inputId="province"
					inputLabel="Province"
					inputName="province"
					inputType="text"
					isDropdown={isRegionsDropDown}
					selectedRegion={profile.homeRegion}
					subdivisions={subdivisions}
					value={province}
					onChange={(text) => onChange(text)}
					onClick={() => setIsRegionsDropDown(!isRegionsDropDown)}
				/>
				<Input
					inputId="cardNo"
					inputLabel="MyDiesel Card Number"
					inputName="cardNo"
					inputType="number"
					value={cardNo}
					onChange={(e) => {
						e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 13)
						setFormData({
							...formData,
							cardNo: e.target.value
						})
					}}
				/>
				<Input
					inputId="company"
					inputLabel="Your Employer (I work for)"
					inputName="company"
					inputType="text"
					isRequired={true}
					value={company}
					onChange={(text) => onChange(text)}
				/>
				<p className="gender">Gender</p>
				<Radio
					imageSelected={Images.radioSelected}
					imageUnselected={Images.radioUnselected}
					radioLabel="Male"
					selected={isGenderMale}
					onPressIcon={() => {
						setIsGenderMale(!isGenderMale)
						setIsGenderNotSpecified(false)
						setIsGenderFemale(false)
						setFormData({
							...formData,
							gender: 'M'
						})
					}}
				/>
				<Radio
					imageSelected={Images.radioSelected}
					imageUnselected={Images.radioUnselected}
					radioLabel="Female"
					selected={isGenderFemale}
					onPressIcon={() => {
						setIsGenderFemale(!isGenderFemale)
						setIsGenderMale(false)
						setIsGenderNotSpecified(false)
						setFormData({
							...formData,
							gender: 'F'
						})
					}}
				/>
				<Radio
					imageSelected={Images.radioSelected}
					imageUnselected={Images.radioUnselected}
					radioLabel="Not Specified"
					selected={isGenderNotSpecified}
					onPressIcon={() => () => {
						setIsGenderNotSpecified(!isGenderNotSpecified)
						setIsGenderMale(false)
						setIsGenderFemale(false)
						setFormData({
							...formData,
							gender: 'NS'
						})
					}}
				/>
				<NotificationsBanner
					isCheckboxSelected={isNotificationsOptedIn}
					title="Notifications"
					toggleCheckbox={() => {
						setIsUpdateButtonDisabled(!isUpdateButtonDisabled)
						setIsNotificationsOptedIn(!isNotificationsOptedIn)
					}}
				/>
				<div style={{ paddingBottom: 100 }}>
					<Button buttonDisabled={isUpdateButtonDisabled} buttonTitle="Update" onPress={() => updateProfile()} />
				</div>
			</div>
			<UserUpdatedModal
				isVisibleModalDetails={isDetailsUpdatedModal}
				onPressButton={() => setIsDetailsUpdatedModal(false)}
			/>
		</div>,
		<AuthError errorBody={authError.authErrorBody} errorTitle={authError.authErrorTitle} key={2} />
	]
}

const mapStateToProps = (state) => ({
	auth: state.container.auth,
	authError: state.general.authError,
	user: state.persisted.user
})

const mapDispatchToProps = (dispatch) => ({
	authErrorShow: (payload) => dispatch(GeneralActions.authErrorShow(payload)),
	authUpdate: (payload) => dispatch(ContainerActions.authUpdate(payload)),
	findSubdivisionRequest: (payload) => dispatch(NetworkActions.findSubdivisionsRequest(payload)),
	getProfileRequest: (payload) => dispatch(NetworkActions.getProfileRequest(payload)),
	getUserMetadataRequest: (payload) => dispatch(NetworkActions.getUserMetadataRequest(payload)),
	putUserMetadataRequest: (payload) => dispatch(NetworkActions.putUserMetadataRequest(payload)),
	updateRequest: (payload) => dispatch(NetworkActions.updateRequest(payload)),
	userUpdate: (payload) => dispatch(PersistedActions.userUpdate(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
