/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { GET_ARTICLES_FAILURE, GET_ARTICLES_REQUEST, GET_ARTICLES_SUCCESS } from './Types'

export const getArticlesSuccess = (payload) => ({
	type: GET_ARTICLES_SUCCESS,
	payload
})

export const getArticlesFailure = (payload) => ({
	type: GET_ARTICLES_FAILURE,
	payload
})

// Get articles from Contentful
export const getArticlesRequest =
	(payload = {}) =>
		(dispatch) => {
			const { vspId, config = {}, ...params } = payload


			dispatch({
				type: GET_ARTICLES_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: getArticlesSuccess,
				actionFailure: getArticlesFailure,
				...config,
				vspId,
				dispatch,
				requestConfig: {
					...config.requestConfig
				},
				isContentful: true
			})
		}
