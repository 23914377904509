/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { PATCH_MESSAGE_STATE_FAILURE, PATCH_MESSAGE_STATE_REQUEST, PATCH_MESSAGE_STATE_SUCCESS } from './Types'

export const patchMessageStateSuccess = (payload) => ({
	type: PATCH_MESSAGE_STATE_SUCCESS,
	payload
})

export const patchMessageStateFailure = (payload) => ({
	type: PATCH_MESSAGE_STATE_FAILURE,
	payload
})

// Change inbox message state.
export const patchMessageStateRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: PATCH_MESSAGE_STATE_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: patchMessageStateSuccess,
				actionFailure: patchMessageStateFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'patch',
					url: `/inbox/messages/${params.id}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					data: {
						state: params.state
					},
					...config.requestConfig
				}
			})
		}
