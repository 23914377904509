export const convertDistance = (distance) => {
	return (distance / 1000).toFixed(2)
}

export const convertToCurrency = (amount) => {
	return `R${(amount / 100).toFixed(2)}`
}

export const maskCardNumber = ({ cardNumber }) => {
	if (cardNumber) {
		return `**** **** * ${cardNumber.slice(-4)}`
	}
}
