/*eslint-disable*/
import React from 'react'

import * as Styles from './InlineStyles'

const CouponItem = ({ imageUrl, onClickCoupon}) => (
	<div style={Styles.couponContainer}>
		<img alt="coupon-image" src={imageUrl} onClick={onClickCoupon} style={{width: 343, height: 150}}/>
		<div style={Styles.buttonContainer}>
			<div style={Styles.rightButton} onClick={onClickCoupon}>
				More Info
			</div>
		</div>
	</div>
)

export default CouponItem
