/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { GET_STORES_FAILURE, GET_STORES_REQUEST, GET_STORES_SUCCESS } from './Types'

export const getStoresSuccess = (payload) => ({
	type: GET_STORES_SUCCESS,
	payload
})

export const getStoresFailure = (payload) => ({
	type: GET_STORES_FAILURE,
	payload
})

// Find all stores
export const getStoresRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: GET_STORES_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.includeGiftingStore) queryString += `includeGiftingStore=${params.includeGiftingStore}&`
			if (params.lat) queryString += `lat=${params.lat}&`
			if (params.latitude) queryString += `latitude=${params.latitude}&`
			if (params.lon) queryString += `lon=${params.lon}&`
			if (params.longitude) queryString += `longitude=${params.longitude}&`
			if (params.maxDistanceInKm) queryString += `maxDistanceInKm=${params.maxDistanceInKm}&`
			if (params.merchantName) queryString += `merchantName=${params.merchantName}&`
			if (params.orderProcessing) queryString += `orderProcessing=${params.orderProcessing}&`
			if (params.pageOffset) queryString += `pageOffset=${params.pageOffset}&`
			if (params.pageSize) queryString += `pageSize=${params.pageSize}&`
			if (params.storeId) queryString += `storeId=${params.storeId}&`

			queryString = queryString.slice(0, -1)

			return handleRequest({
				actionSuccess: getStoresSuccess,
				actionFailure: getStoresFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/stores${queryString}`,
					...config.requestConfig
				}
			})
		}
