/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { CREATE_PIN_FAILURE, CREATE_PIN_REQUEST, CREATE_PIN_SUCCESS } from './Types'

export const createPinSuccess = (payload) => ({
	type: CREATE_PIN_SUCCESS,
	payload
})

export const createPinFailure = (payload) => ({
	type: CREATE_PIN_FAILURE,
	payload
})

// Create a PIN from user's request
export const createPinRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: CREATE_PIN_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.sendValidationOtp) queryString += `sendValidationOtp=${params.sendValidationOtp}&`
			queryString = queryString.slice(0, -1)

			return handleRequest({
				actionSuccess: createPinSuccess,
				actionFailure: createPinFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'post',
					url: `/user/register/pin${queryString}`,
					data: {
						mobile: params.mobile,
						pin: params.pin,
						confirmPin: params.confirmPin
					},
					...config.requestConfig
				}
			})
		}
