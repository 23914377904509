import * as Types from '../Types'

export const storesUpdate = (payload) => ({
	type: Types.STORES_UPDATE,
	payload
})

export const storesClear = (payload) => ({
	type: Types.STORES_CLEAR,
	payload
})
