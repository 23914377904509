export const couponContainer = {
	width: 343,
	backgroundColor: 'white',
	marginLeft: 'auto',
	marginRight: 'auto',
	borderRadius: 8,
	marginBottom: 16
}

export const buttonContainer = {
	backgroundColor: 'white',
	height: 40,
	width: 343,
	display: 'flex',
	borderBottomLeftRadius: 8,
	borderBottomRightRadius: 8,
	borderTopWidth: 0,
	borderTopStyle: 'solid',
	justifyContent: 'center'
}

export const leftButton = {
	width: '50%',
	paddingTop: 10,
	borderBottomLeftRadius: 8,
	fontSize: 13,
	color: '#231F20',
	fontWeight: 'bold'
}

export const rightButton = {
	paddingTop: 10,
	borderBottomRightRadius: 8,
	fontSize: 13,
	color: '#231F20',
	fontWeight: 'bold'
}
