/*eslint-disable*/
import React from 'react'
import './Styles.css'

const TransactionListItem = ({
	storeName,
	transactionDate,
	redemptionStatus,
	amountRedeemed,
	earningType,
	onClickItem
}) => (
	<div className="transaction-container" onClick={onClickItem}>
		<div>
			<div
				style={{
					textAlign: 'left',
					color: 'white',
					fontWeight: '700',
					marginBottom: 8,
					fontSize: 14,
					overflow: 'hidden',
					textOverflow: 'ellipsis'
				}}
			>
				{storeName}
			</div>
			<div style={{ textAlign: 'left', color: 'white', marginBottom: 8, fontSize: 12 }}>{transactionDate}</div>
			<div style={{ textAlign: 'left', color: 'white', marginBottom: 8, fontSize: 12 }}>{earningType}</div>
		</div>
		<div>
			<div style={{ textAlign: 'right', color: 'white', marginBottom: 8, fontSize: 14, fontWeight: '700' }}>
				{redemptionStatus}
			</div>
			<div style={{ textAlign: 'right', color: 'white', fontSize: 12 }}>{amountRedeemed}</div>
		</div>
	</div>
)

export default TransactionListItem
