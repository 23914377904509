export const background = {
	height: '100vh',
	backgroundColor: '#231F20',
	backgroundRepeat: 'repeat-y'
}

export const rewardsCaption = {
	fontSize: 13,
	color: 'white',
	marginBottom: 32,
	textAlign: 'center',
	padding: 32
}

export const bottomButton = {
	backgroundColor: 'white',
	height: 64,
	position: 'fixed',
	width: '100%',
	bottom: 0,
	borderTopWidth: 0.5,
	borderTopStyle: 'solid',
	borderColor: '#E5E5E5'
}

export const buttonText = {
	color: '#231F20',
	fontSize: 15,
	fontWeight: 'bold',
	marginTop: 23
}
