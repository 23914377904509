/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { GET_MESSAGE_FAILURE, GET_MESSAGE_REQUEST, GET_MESSAGE_SUCCESS } from './Types'

export const getMessageSuccess = (payload) => ({
	type: GET_MESSAGE_SUCCESS,
	payload
})

export const getMessageFailure = (payload) => ({
	type: GET_MESSAGE_FAILURE,
	payload
})

// Get inbox message.
export const getMessageRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: GET_MESSAGE_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: getMessageSuccess,
				actionFailure: getMessageFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/inbox/messages/${params.id}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					...config.requestConfig
				}
			})
		}
