/*eslint-disable*/
import handleRequest from '../HandleRequest'
import {
	VIEW_PUBLIC_COUPON_CAMPAIGNS_FAILURE,
	VIEW_PUBLIC_COUPON_CAMPAIGNS_REQUEST,
	VIEW_PUBLIC_COUPON_CAMPAIGNS_SUCCESS
} from './Types'

export const viewPublicCouponCampaignsSuccess = (payload) => ({
	type: VIEW_PUBLIC_COUPON_CAMPAIGNS_SUCCESS,
	payload
})

export const viewPublicCouponCampaignsFailure = (payload) => ({
	type: VIEW_PUBLIC_COUPON_CAMPAIGNS_FAILURE,
	payload
})

// View public coupon campaigns
export const viewPublicCouponCampaignsRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: VIEW_PUBLIC_COUPON_CAMPAIGNS_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.pageOffset) queryString += `pageOffset=${params.pageOffset}&`
			if (params.pageSize) queryString += `pageSize=${params.pageSize}&`
			if (params.storeId) queryString += `storeId=${params.storeId}&`
			if (params.subdivisionId) queryString += `subdivisionId=${params.subdivisionId}&`
			if (params.lon) queryString += `lon=${params.lon}&`
			if (params.lat) queryString += `lat=${params.lat}&`
			if (params.orderBy) queryString += `orderBy=${params.orderBy}&`
			if (params.orderDirection) queryString += `orderDirection=${params.orderDirection}&`
			queryString = queryString.slice(0, -1)

			return handleRequest({
				actionSuccess: viewPublicCouponCampaignsSuccess,
				actionFailure: viewPublicCouponCampaignsFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/cvs/coupons/campaigns${queryString}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					...config.requestConfig
				}
			})
		}
