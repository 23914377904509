/*eslint-disable*/
import React from 'react'

import './Styles.css'
import Images from '../../assets/'
import * as Styles from './InlineStyles'

const StoreDetails = ({
	address,
	storeName,
	distance,
	phone,
	isDetailsVisible,
	onClickModal,
	storeTradingTimes = []
}) => {
	const tradingTimesViews = []

	storeTradingTimes.forEach((daysTradingTimes) => {
		let day = ''
		let timeRange = ''
		const { dayOfWeek, openingTime, closingTime } = daysTradingTimes

		if (dayOfWeek === 0) day = 'Public Holiday '
		else if (dayOfWeek === 1) day = 'Monday :'
		else if (dayOfWeek === 2) day = 'Tuesday :'
		else if (dayOfWeek === 3) day = 'Wednesday :'
		else if (dayOfWeek === 4) day = 'Thursday :'
		else if (dayOfWeek === 5) day = 'Friday :'
		else if (dayOfWeek === 6) day = 'Saturday :'
		else if (dayOfWeek === 7) day = 'Sunday :'

		if (openingTime && closingTime) {
			timeRange = `${openingTime} - ${closingTime}`
		} else if (openingTime === undefined && closingTime === undefined) {
			timeRange = 'Closed'
		}

		tradingTimesViews.push(
			<div key={dayOfWeek} style={{display: 'flex', marginBottom: 8 }}>
				<div
					style={{
						marginTop: 0,
						marginLeft: 16,
						fontSize: 14,
						textAlign: 'left',
						width: '100%',

					}}
				>
					{day}
				</div>
				<div
					style={{
						marginTop: 0,
						marginLeft: 48,
						fontSize: 14,
						width: '100%',
						display: 'flex',
						textAlign: 'right'
					}}
				>
					{timeRange}
				</div>
			</div>
		)
	})

	return (
		isDetailsVisible && (
			<div className="modal" onClick={onClickModal}>
				<div className="details-container" style={{ backgroundColor: 'white' }}>
					<div className="store-title" style={{ display: 'flex', padding: 24 }}>
						<img alt="back-icon" height={10} src={Images.arrowBackBlack} width={5.87} onClick={onClickModal} style={{marginTop: 4}}/>
						<div style={Styles.storeTitle}>{storeName}</div>
					</div>
					<div style={Styles.lineItem}>
						<img alt="back-icon" height={15} src={Images.location} width={11} style={{marginBottom: 8}}/>
						<div style={Styles.textStyles}>{address}</div>
					</div>
					{/*<div style={Styles.lineItem}>*/}
					{/*	<img alt="back-icon" height={14} src={Images.distance} width={11}  style={{marginBottom: 8}}/>*/}
					{/*	<div style={Styles.textStyles}>{`${distance} km`}</div>*/}
					{/*</div>*/}
					{phone && (
						<div style={Styles.lineItem}>
							<img alt="back-icon" height={14} src={phone ? Images.phone : null} width={11} />
							<div style={Styles.textStyles}>{phone}</div>
						</div>
					)}
					{storeTradingTimes && (
						<div style={Styles.storeTradingTimes}>
							<img alt="clock-icon" height={14} src={storeTradingTimes ? Images.clock : null} width={11} />
							<div>{tradingTimesViews}</div>
						</div>
					)}
				</div>
			</div>
		)
	)
}

export default StoreDetails
