import React from 'react'

import Images from '../../assets'
import { BottomTab } from '../../components'

const tabs = [
	{
		id: 0,
		route: '/home',
		icon: Images.homeInactive,
		iconActive: Images.homeActive,
		label: 'HOME'
	},
	{
		id: 1,
		route: '/stores',
		icon: Images.stores,
		iconActive: Images.storesActive,
		label: 'FIND STORES'
	},
	{
		id: 2,
		route: '/inbox',
		icon: Images.inbox,
		iconActive: Images.inboxActive,
		label: 'INBOX'
	},
	{
		id: 3,
		route: '/transactionHistory',
		icon: Images.activity,
		iconActive: Images.activityActive,
		label: 'ACTIVITY'
	},
	{
		id: 4,
		route: '/profile',
		icon: Images.profile,
		iconActive: Images.profileActive,
		label: 'PROFILE'
	}
]

const BottomNavigation = () => <BottomTab tabs={tabs} />

export default BottomNavigation
