import * as Types from '../Types'

const initialState = {
	isUserUpdatingDetails: false,
	mobile: null,
	mobileValidate: null,
	pinConfirm: null,
	pinCreate: null,
	profileDetails: {},
	registerDetails: {},
	subdivisions: []
}

export default (currentState = initialState, action) => {
	const {
		payload: {
			isUserUpdatingDetails,
			mobile,
			mobileValidate,
			pinConfirm,
			pinCreate,
			subdivisions,
			profileDetails,
			registerDetails
		} = {}
	} = action

	switch (action.type) {
		case Types.AUTH_UPDATE:
			return {
				...currentState,
				isUserUpdatingDetails:
					typeof isUserUpdatingDetails === typeof true ? isUserUpdatingDetails : currentState.isUserUpdatingDetails,
				mobile: mobile || currentState.mobile,
				mobileValidate: mobileValidate || currentState.mobileValidate,
				pinConfirm: pinConfirm || currentState.pinConfirm,
				pinCreate: pinCreate || currentState.pinCreate,
				profileDetails: profileDetails || currentState.profileDetails,
				registerDetails: registerDetails || currentState.registerDetails,
				subdivisions: subdivisions || currentState.subdivisions
			}

		case Types.AUTH_CLEAR:
			return {
				...initialState
			}

		default:
			return currentState
	}
}
