/*eslint-disable*/
import React from 'react'

import './Styles.css'
import Input from '../input'

const MobileInput = ({
	isForProfile,
	inputLabel,
	inputType,
	inputId,
	inputName,
	value,
	prefix,
	onChange,
	pattern,
	onClick,
	isMobileFormActive = false
}) =>
	isMobileFormActive ? (
		<>
			<label htmlFor={inputName}>{inputLabel}</label>
			<div className="row" style={{ marginTop: 32 }}>
				{prefix && <span className="input-group-addon">{prefix}</span>}
				<input
					autoFocus={!isForProfile}
					inputMode="numeric"
					required
					className={isForProfile ? "mobile-profile" : "mobile"}
					id={inputId}
					name={inputName}
					pattern={pattern}
					style={{ paddingBottom: 4 }}
					type={inputType}
					value={value}
					onChange={onChange}
				/>
			</div>
		</>
	) : (
		<Input inputId="mobile" inputLabel="Mobile Number *" inputName="mobile" inputType="text" onClick={onClick} />
	)

export default MobileInput
