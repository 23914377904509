/*eslint-disable*/
import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Images from '../../../assets'
import { Header, Input, Button, AuthError } from '../../../components'
import { GeneralActions, NetworkActions, PersistedActions } from '../../../state'

const ForgotPinValidateMobile = ({
	auth: { mobile, registerDetails, pinCreate, pinConfirm },
	authError,
	authErrorShow,
	errorShow,
	getProfileRequest,
	userUpdate,
	loginRequest,
	resendOtpRequest,
	resetPinRequest
}) => {
	const [otp, setOtp] = React.useState('')

	const navigate = useNavigate()

	const onChange = (e) => setOtp(e.target.value)

	const updateUserProfile = ({ profile }) => {
		userUpdate({ profile: profile })
	}

	const onLoginSuccess = async ({ response: { authX } }) => {
		userUpdate({
			authX: authX,
			isAuthenticated: true
		})
		navigate('/home')
		try {
			const response = await getProfileRequest()
			updateUserProfile({ profile: response.user })
		} catch (e) {
			errorShow({
				errorTitle: e.messageTitle,
				errorBody: e.messageBody
			})
		}
	}

	const login = async () => {
		try {
			const response = await loginRequest({
				mobile: registerDetails.mobile || mobile,
				pin: pinCreate,
				config: {
					canReturnAuthX: true
				}
			})
			await onLoginSuccess({ response: response })
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	const resendOtp = async ({ mobile }) => {
		try {
			await resendOtpRequest({
				mobile: mobile
			})
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	const resetPin = async () => {
		try {
			await resetPinRequest({
				mobile: registerDetails.mobile || mobile,
				otp: otp,
				pin: pinCreate,
				confirmPin: pinConfirm
			})
			await login()
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	return [
		<Header headerTitle="Validate Mobile" iconLeft={Images.arrowBackWhite} isBackIcon={true} key={1} route={'/'} />,
		<div key={2} style={{ marginRight: 56, marginLeft: 56 }}>
			<p className="pin-caption" style={{ padding: 16, paddingTop: 0 }}>
				We have sent an SMS with a Code to {registerDetails.mobile || mobile}, please enter it below.
			</p>
			<Input
				inputId="otp"
				inputLabel="One-Time PIN"
				inputName="otp"
				inputType="number"
				value={otp}
				onChange={(text) => onChange(text)}
			/>
			<Button buttonTitle="Proceed" onPress={() => resetPin()} />
			<div className="forgot-pin-button" onClick={() => resendOtp({ mobile: registerDetails.mobile || mobile })}>
				<div style={{ color: 'white', fontWeight: 'bold', marginTop: 25 }}>Resend OTP</div>
			</div>
		</div>,
		<AuthError errorBody={authError.authErrorBody} errorTitle={authError.authErrorTitle} key={3} />
	]
}

const mapStateToProps = (state) => ({
	auth: state.container.auth,
	authError: state.general.authError,
	user: state.persisted.user
})

const mapDispatchToProps = (dispatch) => ({
	authErrorShow: (payload) => dispatch(GeneralActions.authErrorShow(payload)),
	errorShow: (payload) => dispatch(GeneralActions.errorShow(payload)),
	getProfileRequest: (payload) => dispatch(NetworkActions.getProfileRequest(payload)),
	loginRequest: (payload) => dispatch(NetworkActions.loginRequest(payload)),
	resendOtpRequest: (payload) => dispatch(NetworkActions.resendOtpRequest(payload)),
	resetPinRequest: (payload) => dispatch(NetworkActions.resetPinRequest(payload)),
	userUpdate: (payload) => dispatch(PersistedActions.userUpdate(payload)),
	validateMobileNumberRequest: (payload) => dispatch(NetworkActions.validateMobileNumberRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPinValidateMobile)
