/*eslint-disable*/
import React from 'react'

import Images from '../../assets'

const EmptyListState = ({ title, caption }) => (
	<div style={{ marginTop: 163 }}>
		<img alt="empty-state" src={Images.emptyState} style={{height: 183.75, width: 168}}/>
		<p style={{ color: 'white', fontWeight: 'bold', fontSize: 18 }}>{title}</p>
		<p style={{ color: 'white', fontWeight: 'bold', fontSize: 12 }}>{caption}</p>
	</div>
)

export default EmptyListState
