/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { VIEW_CAMPAIGN_STORES_FAILURE, VIEW_CAMPAIGN_STORES_REQUEST, VIEW_CAMPAIGN_STORES_SUCCESS } from './Types'

export const viewCampaignStoresSuccess = (payload) => ({
	type: VIEW_CAMPAIGN_STORES_SUCCESS,
	payload
})

export const viewCampaignStoresFailure = (payload) => ({
	type: VIEW_CAMPAIGN_STORES_FAILURE,
	payload
})

// View stores linked to cvs campaign (user logged in)
export const viewCampaignStoresRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: VIEW_CAMPAIGN_STORES_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.campaignId) queryString += `campaignId=${params.campaignId}&`
			if (params.lon) queryString += `lon=${params.lon}&`
			if (params.lat) queryString += `lat=${params.lat}&`
			queryString = queryString.slice(0, -1)

			return handleRequest({
				actionSuccess: viewCampaignStoresSuccess,
				actionFailure: viewCampaignStoresFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/cvs/coupons/stores/${params.channelId}${queryString}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					...config.requestConfig
				}
			})
		}
