/*eslint-disabled*/
import React from 'react'

import { Spinner, NewVoucher } from '../'

const Button = ({
	isForModal,
	buttonDisabled,
	buttonTitle,
	onPress,
	buttonWidth,
	buttonStyles,
	className,
	buttonClassName,
	hasNewCoupon,
	fetching
}) =>
	fetching ? (
		<Spinner />
	) : (
		<button
			className={className || 'general'}
			disabled={buttonDisabled}
			style={{ marginTop: 40, width: buttonWidth, ...buttonStyles }}
			onClick={onPress}
		>
			{isForModal ? (
				<h4 className={buttonClassName} style={{ color: 'white' }}>
					{buttonTitle}
				</h4>
			) : (
				<h4
					className={buttonClassName || 'button-text'}
					style={{
						color: buttonDisabled ? '#BDBDBD' : '#231F20',
						display: hasNewCoupon ? 'flex' : 'initial',
						marginTop: 16,
						justifyContent: 'center'
					}}
				>
					{buttonTitle}
					{hasNewCoupon && <NewVoucher containerStyles={{ marginTop: 2, marginLeft: 4 }} />}
				</h4>
			)}
		</button>
	)

export default Button
