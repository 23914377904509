/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { FORGOT_PIN_FAILURE, FORGOT_PIN_REQUEST, FORGOT_PIN_SUCCESS } from './Types'

export const forgotPinSuccess = (payload) => ({
	type: FORGOT_PIN_SUCCESS,
	payload
})

export const forgotPinFailure = (payload) => ({
	type: FORGOT_PIN_FAILURE,
	payload
})

// User forgot PIN and user is not logged in. App Sends otp to confirm
export const forgotPinRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: FORGOT_PIN_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: forgotPinSuccess,
				actionFailure: forgotPinFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'post',
					url: '/login/forgot',
					data: {
						mobile: params.mobile,
						email: params.email
					},
					...config.requestConfig
				}
			})
		}

