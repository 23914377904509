export const errorTitle = {
	fontSize: 20,
	fontWeight: '600',
	color: '#525252',
	marginBottom: 11,
	marginTop: 24
}

export const errorBody = {
	fontSize: 14,
	fontWeight: '400',
	color: '#525252',
	marginBottom: 30
}

export const detailsContainer = {
	backgroundColor: 'white',
	width: '90%',
	borderRadius: 8,
	margin: 'auto',
	top: 129,
	left: 0,
	right: 0,
	position: 'absolute'
}
