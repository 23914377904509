/*eslint-disable*/
import React from 'react'
import './Styles.css'

const ArticleItem = ({ articleImage, title, onClickArticle }) => [
	<div key={title} className="article" onClick={() => onClickArticle()}>
		<img
			alt="article-image"
			height={150}
			key={1}
			src={articleImage}
			style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8, marginBottom: 0 }}
			width={300}
		/>
		<div
			className="title-container"
			key={2}
			style={{
				backgroundColor: 'white',
				width: 300,
				height: 40,
				justifyContent: 'center',
				alignItems: 'center',
				borderBottomLeftRadius: 8,
				borderBottomRightRadius: 8,
				marginBottom: 16,
				paddingTop: 8
		}}>
			{title}
		</div>
	</div>
]

export default ArticleItem
