export const itemContainer = {
	justifyContent: 'space-between',
	display: 'flex',
	height: 67,
	borderBottomStyle: 'solid',
	borderBottomColor: '#64A5E8',
	borderBottomWidth: 0.5,
	marginLeft: 16,
	marginRight: 16
}

export const body = {
	color: 'white',
	fontSize: 12,
	fontWeight: '300',
	width: 250,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}
