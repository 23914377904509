/* eslint-disable */
import React from 'react'
import DatePicker from 'react-datepicker'
import * as Styles from './InlineStyles'

const DateInput = ({dateFormat, placeholderText, selectedDate, inputLabel, onChange, inputName}) => (
	<div style={Styles.container}>
		<label htmlFor={inputName}>{inputLabel}</label>
		<DatePicker
			showYearDropdown
			dateFormat={dateFormat}
			dropdownMode="select"
			placeholderText={placeholderText}
			selected={selectedDate}
			onChange={onChange}
		/>
	</div>
)

export default DateInput
