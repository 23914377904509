import activity from './icons/activity@3x.png'
import activityActive from './icons/activityActive@3x.png'
import arrowBack from './icons/arrowBack@3x.png'
import arrowBackBlack from './icons/arrowBackBlack@3x.png'
import arrowBackWhite from './icons/arrowBackWhite@3x.png'
import arrowForward from './icons/arrowForward.png'
import checkboxSelected from './icons/checkboxSelected@3x.png'
import checkboxSelectedWhite from './icons/checkboxSelectedWhite@3x.png'
import checkboxUnselected from './icons/checkboxUnselected@3x.png'
import clock from './icons/clock@3x.png'
import distance from './icons/distance@3x.png'
import homeActive from './icons/homeActive@3x.png'
import homeInactive from './icons/homeInactive@3x.png'
import iconClose from './icons/IconClose.png'
import iconCloseWhite from './icons/iconCloseWhite@3x.png'
import inbox from './icons/inbox@3x.png'
import inboxActive from './icons/inboxActive@3x.png'
import location from './icons/location@3x.png'
import locationActive from './icons/locationActive.png'
import menu from './icons/menu@3x.png'
import more from './icons/more.png'
import myDieselLogo from './icons/myDieselLogo.png'
import phone from './icons/phone@3x.png'
import pinEmpty from './icons/pinEmpty@3x.png'
import pinFilled from './icons/pinFilled@3x.png'
import profile from './icons/profile@3x.png'
import profileActive from './icons/profileActive@3x.png'
import radioSelected from './icons/radioSelected@3x.png'
import radioUnselected from './icons/radioUnselected@3x.png'
import stores from './icons/stores@3x.png'
import storesActive from './icons/storesActive@3x.png'
import transactionFailed from './icons/transactionFailed@3x.png'
import transactionProcessing from './icons/transactionProcessing@3x.png'
import transactionSuccess from './icons/transactionSuccess@3x.png'
import walkthroughImage1 from './icons/walkthroughImage1.png'
import walkthroughImage2 from './icons/walkthroughImage2.png'
import blueLogo from './images/blueLogo.png'
import congrats from './images/congrats.png'
import emptyState from './images/emptyState.png'
import homeArrowDown from './images/homeArrowDown@3x.png'
import homeBackground from './images/homeBackground.png'
import homeFuelMeter from './images/homeFuelMeter@3x.png'
import homeLogo from './images/homeLogo@3x.png'
import homePointer from './images/homePointer.png'
import yoyoLogo from './images/yoyoLogo 1@3x.png'

export default {
	activity,
	activityActive,
	arrowBack,
	arrowBackBlack,
	arrowBackWhite,
	arrowForward,
	blueLogo,
	clock,
	checkboxSelected,
	checkboxSelectedWhite,
	checkboxUnselected,
	congrats,
	distance,
	emptyState,
	homeActive,
	homeArrowDown,
	homeInactive,
	homeBackground,
	homeFuelMeter,
	homeLogo,
	homePointer,
	iconClose,
	iconCloseWhite,
	inbox,
	inboxActive,
	location,
	locationActive,
	menu,
	more,
	myDieselLogo,
	phone,
	pinEmpty,
	pinFilled,
	profile,
	profileActive,
	radioSelected,
	radioUnselected,
	stores,
	storesActive,
	transactionFailed,
	transactionProcessing,
	transactionSuccess,
	walkthroughImage1,
	walkthroughImage2,
	yoyoLogo
}
