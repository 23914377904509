/*eslint-disable*/
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Images from '../../assets/'
import { Header, TransactionListItem, DetailsModal, EmptyListState } from '../../components'
import Config from '../../config/AppConfiguration'
import { NetworkActions, PersistedActions } from '../../state'
import { convertToCurrency } from '../../utils'

const TransactionHistory = ({ viewTransactionHistoryRequest, listsUpdate, transactions }) => {
	const { giftPurchases, giftsReceived, inStoreTransactions } = transactions

	const [isTransactionDetailsVisible, setIsTransactionDetailsVisible] = useState(false)

	const [selectedTransaction, setSelectedTransaction] = useState({})

	useEffect(async () => {
		await viewTransactionHistory()
	}, [])

	const viewTransactionHistory = async () => {
		try {
			const response = await viewTransactionHistoryRequest()

			await listsUpdate({
				transactions: {
					externalRewardsReceived: response.externalRewardsReceived,
					giftPurchases: response.giftPurchases,
					giftsReceived: response.giftsReceived,
					inStoreTransactions: response.inStoreTransactions,
					thirdPartyTransactions: response.thirdPartyTransactions
				}
			})
		} catch (e) {}
	}

	const mergeLists = ({ inStoreTransactionList, giftReceivedList, giftsPurchasedList }) => {
		const giftsReceived = _.compact(giftReceivedList)
		const tempList = []

		giftsReceived.map((giftReceived) => tempList.push({ ...giftsReceived, createDate: giftReceived.receivedDate }))

		const mergedList = _.concat(_.compact(inStoreTransactionList), _.compact(giftsPurchasedList), tempList)

		return _.reverse(_.sortBy(mergedList, ['createDate']))
	}

	const allTransactionsList = mergeLists({
		inStoreTransactionList: inStoreTransactions,
		giftReceivedList: giftsReceived,
		giftsPurchasedList: giftPurchases
	})

	const displayTransactionDetails = ({ item }) => (
		<DetailsModal
			amountRedeemed={convertToCurrency(item.totalPaymentApproved)}
			isVisibleDetails={isTransactionDetailsVisible}
			pointsEarned={
				!_.isEmpty(item.earnings) && item?.earnings[0].loyaltyCampaignName === 'MyDiesel FE Dial Reward' ? 1 : null
			}
			reward={convertToCurrency(item.totalDiscountApproved)}
			storeName={item.storeName}
			transactionDate={moment(item.createDate).format('DD/MM/YYYY')}
			onPressButton={() => setIsTransactionDetailsVisible(false)}
		/>
	)

	const renderTransactionsList = () => {
		let renderedList
		let loyaltyCampaignName

		const {
			debugMode: { isDebugMode }
		} = Config.state

		isDebugMode ? loyaltyCampaignName = 'MyDiesel FE Dial Reward' : loyaltyCampaignName = 'MyDiesel Loyalty Surprise and Delight 2022 (replica of int 712) '

		if (!_.isEmpty(allTransactionsList)) {
			renderedList = allTransactionsList.map((transaction, index) => {
				// console.log(transaction)
				let redemptionStatus
				let earningType = null
				let amountRedeemed = null
				if (!_.isEmpty(transaction.earnings) && transaction?.earnings[0].loyaltyCampaignName === loyaltyCampaignName) {
					redemptionStatus = 'Earned'
					earningType = 'Rewards Earned'
					amountRedeemed = '1 Visit'
				} else if (transaction.totalDiscountApproved !== 0) {
					redemptionStatus = 'Redeemed'
					earningType = 'Rewards Redeemed'
				}

				return (
					<TransactionListItem
						amountRedeemed={
							transaction.totalDiscountApproved > 0
								? convertToCurrency(transaction.totalDiscountApproved)
								: amountRedeemed
						}
						earningType={earningType}
						key={index}
						redemptionStatus={redemptionStatus}
						storeName={transaction.storeName}
						transactionDate={moment(transaction.createDate).format('LLL')}
						onClickItem={() => {
							setIsTransactionDetailsVisible(true)
							setSelectedTransaction(transaction)
						}}
					/>
				)
			})
		} else {
			renderedList = <EmptyListState caption="You have no transactions that took place." title="NO ACTIVITY YET..." />
		}
		return <div style={{ marginLeft: 16, marginRight: 16, width: '100%', paddingBottom: 80 }}>{renderedList}</div>
	}

	return (
		<div style={{ height: '100vh', backgroundColor: '#231F20' }}>
			<Header headerTitle="Activity" iconLeft={null} iconRight={Images.menu} route="/home" />
			<div style={{ paddingRight: 32 }}>
				{renderTransactionsList()}
				{displayTransactionDetails({ item: selectedTransaction })}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	transactions: state.persisted.lists.transactions
})

const mapDispatchToProps = (dispatch) => ({
	listsUpdate: (payload) => dispatch(PersistedActions.listsUpdate(payload)),
	viewTransactionHistoryRequest: (payload) => dispatch(NetworkActions.viewTransactionHistoryRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory)
