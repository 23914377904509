/*eslint-disable*/
import _ from 'lodash'
import React from 'react'

import Images from '../../assets'
import Button from '../button'
import * as Styles from '../detailsModal/InlineStyles'

const TransactionState = ({
	state,
	title,
	caption,
	icon,
	isVisibleReceipt,
	transactionData,
	isAnimated,
	onPressOk,
	onPressCancel,
	onPressRetry
}) => {
	if (state === 'PROCESSING') {
		icon = Images.transactionProcessing
		title = 'Processing'
		caption = 'Please wait...'
	}

	if (state === 'SUCCESS') {
		icon = Images.transactionSuccess
		title = 'Transaction Processed'
	}
	if (state === 'ERROR') {
		icon = Images.transactionFailed
		title = 'Transaction Declined'
		caption = 'Do you want to retry?'
	}

	const topContent = () => (
		<div>
			<img
				alt="icon"
				className="processing"
				height={51}
				src={icon}
				style={{ marginTop: 40, animation: isAnimated ? 'spin 2s linear infinite' : null }}
				width={51}
			/>
			<div style={{ fontSize: 20, color: '#525252', marginTop: 24 }}>{title}</div>
			{caption && <div style={{ fontSize: 12, color: '#525252' }}>{caption}</div>}
		</div>
	)

	// const receipt = () => (
	// 	<div>
	// 		<div style={{ display: 'flex', marginTop: 36, justifyContent: 'space-around', marginBottom: 10 }}>
	// 			<div>StoreName</div>
	// 			<div>Storename</div>
	// 		</div>
	// 		<div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 10 }}>
	// 			<div>StoreName</div>
	// 			<div>Storename</div>
	// 		</div>
	// 		<div style={{ backgroundColor: '#B2D2F3', height: 1, width: '80%', margin: 'auto' }} />
	// 		<div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 24, marginBottom: 24 }}>
	// 			<div>StoreName</div>
	// 			<div>Storename</div>
	// 		</div>
	// 	</div>
	// )

	const renderButtons = () => {
		if (state === 'SUCCESS')
			return (
				<div style={{ marginBottom: 60 }}>
					<Button isForModal buttonClassName="alert-button-text" buttonTitle="Okay" className="alert-button" onPress={onPressOk} />
				</div>
			)

		if (state === 'ERROR')
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-around',
						paddingLeft: 12,
						paddingRight: 12,
						marginBottom: 60
					}}
				>
					<div style={{ width: 150 }}>
						<Button
							isForModal
							buttonClassName="alert-button-text"
							buttonTitle="Cancel"
							buttonWidth={150}
							className="alert-button"
							onPress={onPressCancel}
						/>
					</div>
					<div style={{ width: 150 }}>
						<Button
							isForModal
							buttonClassName="alert-button-text"
							buttonTitle="Retry"
							buttonWidth={150}
							className="alert-button"
							onPress={onPressRetry}
						/>
					</div>
				</div>
			)
		return null
	}

	return (
		isVisibleReceipt && (
			<div className="transaction-details-modal">
				<div style={Styles.detailsContainer}>
					{topContent({
						isAnimated: state === 'PROCESSING'
					})}
					{/*{state !== 'ERROR' && receipt()}*/}
					{renderButtons()}
				</div>
			</div>
		)
	)
}

export default TransactionState
