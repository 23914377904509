import * as Types from '../Types'

const initialState = {
	coupons: [],
	couponSelected: {},
	couponSelectedIsVisible: false,
	latitude: null,
	longitude: null,
	selectedStore: {},
	storeDetailsIsVisible: false,
	stores: [],
	storesIsVisible: false
}

export default (currentState = initialState, action) => {
	const {
		payload: {
			coupons,
			couponSelected,
			couponSelectedIsVisible,
			latitude,
			longitude,
			selectedStore,
			storeDetailsIsVisible,
			stores,
			storesIsVisible
		} = {}
	} = action

	switch (action.type) {
		case Types.COUPONS_UPDATE:
			return {
				...currentState,
				coupons: coupons || currentState.coupons,
				couponSelected: couponSelected || currentState.couponSelected,
				couponSelectedIsVisible:
					typeof couponSelectedIsVisible === typeof true
						? couponSelectedIsVisible
						: currentState.couponSelectedIsVisible,
				latitude: latitude || currentState.latitude,
				longitude: longitude || currentState.longitude,
				selectedStore: selectedStore || currentState.selectedStore,
				storeDetailsIsVisible:
					typeof storeDetailsIsVisible === typeof true ? storeDetailsIsVisible : currentState.storeDetailsIsVisible,
				stores: stores || currentState.stores,
				storesIsVisible: typeof storesIsVisible === typeof true ? storesIsVisible : currentState.storesIsVisible
			}

		case Types.COUPONS_CLEAR:
			return {
				...initialState
			}

		default:
			return currentState
	}
}
