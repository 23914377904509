/*eslint-disable*/
import React from 'react'

import Images from '../../assets'
import * as Styles from '../storeDetails/InlineStyles'

const StoreDetailsMap = ({ storeName, address, distance, phone, onClickTile }) => (
	<div
		className="wrapper"
		style={{
			width: 300,
			height: 200,
			borderRadius: 8,
			marginRight: 22,
			marginLeft: 22,
			borderStyle: 'solid',
			borderColor: '#C9C9C9',
			borderWidth: 0.2,
			backgroundColor: "white"
		}}
		onClick={onClickTile}
	>
		<div className="store-title" style={{ display: 'flex', padding: 24 }}>
			<p style={Styles.storeTitle}>{storeName}</p>
			<img alt="back-icon" height={14} src={Images.more} width={3.5}/>
		</div>
		<div style={Styles.lineItem}>
			<img alt="back-icon" height={14} src={Images.location} width={11} />
			<p style={Styles.textStyles}>{address}</p>
		</div>
		<div style={Styles.lineItem}>
			<img alt="back-icon" height={14} src={Images.distance} width={11} />
			<p style={Styles.textStyles}>{`${distance} km`}</p>
		</div>
		{phone && <div style={Styles.lineItem}>
			<img alt="back-icon" height={14} src={Images.phone} width={11} />
			<p style={Styles.textStyles}>{phone}</p>
		</div>}
	</div>
)

export default StoreDetailsMap
