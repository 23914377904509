import * as Types from '../Types'

const INITIAL_STATE = {
	inbox: {
		messages: [],
		totalUnreadMessages: 0
	},
	transactions: {
		externalRewardsReceived: [],
		giftPurchases: [],
		giftsReceived: [],
		inStoreTransactions: [],
		thirdPartyTransactions: []
	}
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case Types.LISTS_UPDATE:
			return {
				...state,
				inbox: {
					messages:
						action.payload.inbox && action.payload.inbox.messages
							? action.payload.inbox.messages
							: state.inbox.messages,
					totalUnreadMessages:
						action.payload.inbox && action.payload.inbox.totalUnreadMessages >= 0
							? action.payload.inbox.totalUnreadMessages
							: state.inbox.totalUnreadMessages
				},
				transactions: {
					giftPurchases:
						action.payload.transactions && action.payload.transactions.giftPurchases
							? action.payload.transactions.giftPurchases
							: state.transactions.giftPurchases,
					giftsReceived:
						action.payload.transactions && action.payload.transactions.giftsReceived
							? action.payload.transactions.giftsReceived
							: state.transactions.giftsReceived,
					inStoreTransactions: action?.payload?.transactions?.inStoreTransactions
						? action.payload.transactions.inStoreTransactions
						: state.transactions.inStoreTransactions,
					externalRewardsReceived:
						action.payload.transactions && action.payload.transactions.externalRewardsReceived
							? action.payload.transactions.externalRewardsReceived
							: state.transactions.externalRewardsReceived,
					thirdPartyTransactions:
						action.payload.transactions && action.payload.transactions.thirdPartyTransactions
							? action.payload.transactions.thirdPartyTransactions
							: state.transactions.thirdPartyTransactions
				}
			}

		case Types.LISTS_CLEAR:
			return {
				...INITIAL_STATE
			}

		default:
			return state
	}
}
