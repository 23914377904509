export const lineItem = {
	display: 'flex',
	justifyContent: 'flex-start',
	paddingLeft: 24,
	paddingRight: 24,
	marginBottom: 12
}

export const textStyles = {
	marginTop: 0,
	marginLeft: 16,
	fontSize: 14,
	width: 250,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left'
}

export const storeTitle = {
	width: '100%',
	fontWeight: 'bold',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	textAlign: 'center',
	fontSize: 15,
	marginLeft: 12
}

export const storeTradingTimes = {
	display: 'flex',
	paddingLeft: 24,
	paddingRight: 24,
	width: '100%'
}
