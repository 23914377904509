export const detailsContainer = {
	backgroundColor: 'white',
	width: '90%',
	borderRadius: 8,
	margin: 'auto',
	top: 129,
	left: 0,
	right: 0,
	position: 'absolute'
}

export const storeName = {
	fontSize: 20,
	fontWeight: '600',
	color: '#525252',
	marginBottom: 11
}

export const date = {
	fontSize: 12,
	fontWeight: '400',
	color: '#525252',
	marginBottom: 30
}

export const transactionInfo = {
	display: 'flex',
	justifyContent: 'space-between',
	marginLeft: 34,
	marginRight: 34
}

export const amountTitle = {
	fontSize: 12,
	fontWeight: '600',
	color: '#525252',
	textAlign: 'left'
}
export const amountRedeemed = {
	fontSize: 12,
	fontWeight: '400',
	color: '#525252'
}

export const rewardsInfo = {
	display: 'flex',
	justifyContent: 'space-between',
	marginLeft: 34,
	marginRight: 34
}

export const rewardRedeemedTitle = {
	textAlign: 'left',
	fontSize: 12,
	fontWeight: '600',
	color: '#525252'
}

export const reward = {
	fontSize: 12,
	fontWeight: '400',
	color: '#525252'
}
