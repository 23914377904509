/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { FIND_SUBDIVISIONS_FAILURE, FIND_SUBDIVISIONS_REQUEST, FIND_SUBDIVISIONS_SUCCESS } from './Types'

export const findSubdivisionsSuccess = (payload) => ({
	type: FIND_SUBDIVISIONS_SUCCESS,
	payload
})

export const findSubdivisionsFailure = (payload) => ({
	type: FIND_SUBDIVISIONS_FAILURE,
	payload
})

// Find all subdivisions
export const findSubdivisionsRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: FIND_SUBDIVISIONS_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: findSubdivisionsSuccess,
				actionFailure: findSubdivisionsFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: '/subdivisions',
					...config.requestConfig
				}
			})
		}
