import * as Types from '../Types'

export const authErrorShow = (payload) => ({
	type: Types.AUTH_ERROR_SHOW,
	payload
})

export const authErrorClear = (payload) => ({
	type: Types.AUTH_ERROR_CLEAR,
	payload
})
