import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { ContainerReducers, GeneralReducers, NetworkReducers, PersistedReducers } from './'

const reduxPersistConfig = {
	blacklist: ['container', 'network', 'general'],
	key: 'root',
	storage
}

const rootReducer = persistCombineReducers(reduxPersistConfig, {
	container: ContainerReducers,
	network: NetworkReducers,
	persisted: PersistedReducers,
	general: GeneralReducers
})

export default rootReducer
