import React from 'react'

import Images from '../../assets'
import Pin from '../pin'

const PinState = ({ state, onEndReached, renderBelowPin }) => {
	const pinCaption = () => {
		switch (state) {
			case 'CREATE_PIN':
				// eslint-disable-next-line react/jsx-no-literals
				return (
					// eslint-disable-next-line react/jsx-no-literals
					<p className="pin-caption">
						Create your unique PIN that we will use to secure your profile and transactions.
					</p>
				)
			case 'CONFIRM_PIN':
				return (
					// eslint-disable-next-line react/jsx-no-literals
					<p className="pin-caption">Re-enter your chosen PIN.</p>
				)

			case 'UPDATE_PIN':
				return (
					// eslint-disable-next-line react/jsx-no-literals
					<p className="pin-caption">Please provide your current PIN in order to proceed.</p>
				)
		}
	}

	return (
		<div>
			{pinCaption()}
			<Pin
				imagePinActive={Images.pinFilled}
				imagePinInactive={Images.pinEmpty}
				pinLength={4}
				onEndReached={onEndReached}
			/>
			{renderBelowPin && renderBelowPin()}
		</div>
	)
}

export default PinState
