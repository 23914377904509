export const AUTH_CLEAR = 'AUTH_CLEAR'
export const AUTH_UPDATE = 'AUTH_UPDATE'

export const STORES_CLEAR = 'STORES_CLEAR'
export const STORES_UPDATE = 'STORES_UPDATE'

export const COUPONS_CLEAR = 'COUPONS_CLEAR'
export const COUPONS_UPDATE = 'COUPONS_UPDATE'

export const INBOX_CLEAR = 'INBOX_CLEAR'
export const INBOX_UPDATE = 'INBOX_UPDATE'

export const PAYMENT_CLEAR = 'PAYMENT_CLEAR'
export const PAYMENT_RESET_WI_CODE = 'PAYMENT_RESET_WI_CODE'
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE'
