/* eslint-disable*/
import React from 'react'
import { connect } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import BottomNavigation from '../../navigation/bottomNavigation'
import DrawerNavigation from '../../navigation/drawerNavigation'
import { GeneralActions } from '../../state'
import ErrorModal from '../errorModal'

const PrivateRoute = ({ error, errorClear, user: { isAuthenticated, showDrawer } }) => {
	let location = useLocation()

	const hideBottomNavigation =
		location.pathname === '/myRewards' ||
		location.pathname === '/scanEarn' ||
		location.pathname === '/participatingStores' ||
		location.pathname === '/scanMyRewards'

	if (isAuthenticated) {
		return [
			<Outlet key={1} />,
			!hideBottomNavigation && <BottomNavigation key={2} />,
			showDrawer && <DrawerNavigation key={3} />,
			<ErrorModal
				errorBody={error.errorBody}
				errorTitle={error.errorTitle}
				isErrorVisible={error.isErrorVisible}
				key={4}
				onPressButton={() => errorClear()}
			/>
		]
	}

	return <Navigate to="/" />
}

const mapStateToProps = (state) => ({
		error: state.general.error,
		user: state.persisted.user
})


const mapDispatchToProps = (dispatch) => ({
	errorClear: (payload) => dispatch(GeneralActions.errorClear(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
