/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { CALCULATE_USER_BALANCE_FAILURE, CALCULATE_USER_BALANCE_REQUEST, CALCULATE_USER_BALANCE_SUCCESS } from './Types'

export const calculateUserBalanceSuccess = (payload) => ({
	type: CALCULATE_USER_BALANCE_SUCCESS,
	payload
})

export const calculateUserBalanceFailure = (payload) => ({
	type: CALCULATE_USER_BALANCE_FAILURE,
	payload
})

// Get loyalty balance
export const calculateUserBalanceRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: CALCULATE_USER_BALANCE_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: calculateUserBalanceSuccess,
				actionFailure: calculateUserBalanceFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: '/loyalty/balances',
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					...config.requestConfig
				}
			})
		}
