/*eslint-disable*/
import React from 'react'

import Button from '../button'
import * as Styles from './InlineStyles'

const ErrorModal = ({ isErrorVisible, errorTitle, errorBody, onPressButton }) =>
	isErrorVisible && (
		<div className="transaction-details-modal">
			<div style={Styles.detailsContainer}>
				<div style={Styles.errorTitle}>{errorTitle}</div>
				<div style={Styles.errorBody}>{errorBody}</div>
				<div style={{ marginBottom: 36 }}>
					<Button
						isForModal
						buttonClassName="alert-button-text"
						buttonDisabled={false}
						buttonTitle="Dismiss"
						className="alert-button"
						onPress={onPressButton}
					/>
				</div>
			</div>
		</div>
	)

export default ErrorModal
