/*eslint-disable*/
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Images from '../../../assets'
import {AuthError, Button, Header, MobileInput} from '../../../components'
import {ContainerActions, GeneralActions, NetworkActions} from '../../../state/'

const ForgotPin = ({ authError, forgotPinRequest, authErrorShow, authUpdate }) => {

	const [mobile, setMobile] = useState('')

	const onChange = (e) => setMobile(e)

	const mobilePrefix = '+27'

	const formattedMobileNumber = `${mobilePrefix}${mobile}`

	const navigate = useNavigate()

	const forgotPin = async () => {
		try {
			await forgotPinRequest({
				mobile: formattedMobileNumber
			})
			authUpdate({
				mobile: formattedMobileNumber
			})
			navigate('/forgotPinCreatePin')
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	return (
		<React.Fragment>
			<Header headerTitle="Forgot Pin" iconLeft={Images.iconCloseWhite} route="/login" />
			<div style={{ marginLeft: 40, marginRight: 40 }}>
				<p className="pin-caption">Please provide your mobile number so that we can verify your account</p>
				<MobileInput
					isMobileFormActive={true}
					inputId="mobile"
					inputLabel="Mobile"
					inputName="mobile"
					inputType="text"
					prefix={mobilePrefix}
					value={mobile}
					onChange={(text) => {
						let mobileNum = text.target.value
						let zero = mobileNum.charAt(0)

						if (zero === '0') {
							mobileNum = mobileNum.slice(1)
						}

						let parseMobileNumber = mobileNum.slice(0, 9)

						onChange(parseMobileNumber)
					}}
				/>
				<Button buttonTitle="Proceed" onPress={() => forgotPin()} />
			</div>
			<AuthError errorBody={authError.authErrorBody} errorTitle={authError.authErrorTitle} />
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	authError: state.general.authError,
})

const mapDispatchToProps = (dispatch) => ({
	authErrorShow: (payload) => dispatch(GeneralActions.authErrorShow(payload)),
	authUpdate: (payload) => dispatch(ContainerActions.authUpdate(payload)),
	forgotPinRequest: (payload) => dispatch(NetworkActions.forgotPinRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPin)
