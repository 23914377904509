import * as Types from '../Types'

const initialState = {
	authErrorTitle: null,
	authErrorBody: null,
	isErrorVisible: false
}

export default (state = initialState, action) => {
	switch (action.type) {
		case Types.AUTH_ERROR_SHOW:
			return {
				...state,
				authErrorBody: action.payload ? action.payload.authErrorBody : null,
				authErrorTitle: action.payload ? action.payload.authErrorTitle : null,
				isErrorVisible: true
			}
		case Types.AUTH_ERROR_CLEAR:
			return {
				...initialState
			}
		default:
			return state
	}
}
