import axios from 'axios'
import _ from 'lodash'

import Config from '../../../config/AppConfiguration'

const { createClient } = require('contentful/dist/contentful.browser.min.js')

const contentfulApi = createClient({
	space: '28ethvy824eq',
	accessToken: '98628fb3eb8626b0292a48e5a9743c4aa938f3fa51d69c8ba275219a997a1c6c'
})

export default ({
	actionSuccess,
	actionFailure,
	dispatch,
	requestConfig,
	vspId = Config.state.contentfulVspId,
	canReject = true,
	canReturnAuthX = false,
	canShowError = false,
	isContentful = false,
	isGoogleAPI = false
}) =>
	new Promise((resolve, reject) => {
		const handleSuccess = (response) => {
			const { data, headers } = response

			if (canReturnAuthX) data.authX = headers['authorization-x']

			dispatch(actionSuccess(data))
			resolve(data)
		}

		const handleError = (error) => {
			const { response } = error
			let errorResponse

			if (isContentful) {
				errorResponse = {
					code: response.status,
					messageTitle: 'Contentful',
					messageBody: !_.isEmpty(response.data) ? response.data.message : null
				}
			} else if (!response) {
				// Set the error for an Axios timeout
				errorResponse = {
					code: 1504,
					messageTitle: 'Connection Error',
					messageBody: 'It took too long to get a response, please try again'
				}
			} else if (_.isEmpty(response.data) || (!_.isEmpty(response.data) && !response.data.messageTitle)) {
				// Set the error for a bad server response
				errorResponse = {
					code: 1500,
					messageTitle: 'Something Went Wrong',
					messageBody: 'An unexpected error occurred, please wait a minute & then try again'
				}
			} else {
				// Use the server response
				errorResponse = response.data
			}

			dispatch(actionFailure(errorResponse))
			if (canReject) reject(errorResponse)
			if (canShowError) {
				// dispatch(
				// 	toastShow({
				// 		colorBackground: Config.errorToastColorBackground,
				// 		textTitleString: errorResponse.messageTitle,
				// 		textBodyString: errorResponse.messageBody
				// 	})
				// )
			}

			// if (Bugsnag !== null) {
			// 	const endpoint = isContentful ? 'Contentful' : `${requestConfig.method.toUpperCase()} ${requestConfig.url}`
			// 	Bugsnag.notify(new Error(endpoint), (report) => {
			// 		report.context = 'API Request' // eslint-disable-line no-param-reassign
			// 		report.metadata = {
			// 			// eslint-disable-line no-param-reassign
			// 			metadata: {
			// 				endpoint,
			// 				response: errorResponse
			// 			}
			// 		}
			// 	})
			// }
		}

		if (isContentful) {
			contentfulApi
				.getEntries({
					content_type: 'clientContainer',
					'fields.vspId': vspId,
					...requestConfig
				})
				.then((response) => {
					dispatch(actionSuccess(response))
					resolve(response)
				})
				.catch((error) => handleError(error))
		} else if (isGoogleAPI) {
			axios({
				timeout: 30000,
				...requestConfig
			})
				.then((response) => {
					return handleSuccess(response)
				})
				.catch((error) => handleError(error))
		} else {
			axios({
				baseURL: Config.state.baseUrl,
				timeout: 30000,
				...requestConfig,
				headers: {
					'Content-Type': 'application/json',
					...requestConfig.headers
				}
			})
				.then((response) => handleSuccess(response))
				.catch((error) => handleError(error))
		}
	})
