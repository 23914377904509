/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Images from '../../assets'
import { WiCode, Spinner, Header, TransactionState } from '../../components'
import { ContainerActions, NetworkActions } from '../../state'
import Poll from '../../utils/Poll'

const ScanMyRewards = ({
	retrieveUserTransactionRequest,
	retrieveWiCodeRequest,
	retrieveWiCodeV2,
	paymentUpdate,
	payment,
	payment: { trxId }
}) => {
	const [wiQr, setWiQr] = useState('')

	const [wiCode, setWiCode] = useState('')

	const [receiptState, setReceiptState] = useState(null)

	const [receiptData, setReceiptData] = useState({})

	const navigate = useNavigate()

	useEffect(() => {
		const retrieveWicode = async () => {
			try {
				const response = await retrieveWiCodeRequest({
					tenderType: '0' // Loyalty
				})
				setWiQr(response.wiQR)
				setWiCode(response.wiCode)
				paymentUpdate({
					trxId: response.trxId
				})
				pollWiCodeTransactionStatus({ trxId: response.trxId, conditionValuesSuccess: ['SETTLEMENT_SUCCESSFUL'] })
			} catch (e) {}
		}

		retrieveWicode()
	}, [])

	// const retrieveWiCode = async () => {
	// 	try {
	// 		const response = await retrieveWiCodeRequest({
	// 			tenderType: '0' // Loyalty
	// 		})
	// 		setWiQr(response.wiQR)
	// 		setWiCode(response.wiCode)
	// 		paymentUpdate({
	// 			trxId: response.trxId
	// 		})
	// 		pollWiCodeTransactionStatus({ trxId: response.trxId, conditionValuesSuccess: ['SETTLEMENT_SUCCESSFUL'] })
	// 	} catch (e) {}
	// }

	const pollWiCodeTransactionStatus = ({
		trxId,
		conditionValuesSuccess = ['SETTLEMENT_SUCCESSFUL', 'SUCCESSFUL_PENDING_RECON']
	} = {}) => {
		console.log(trxId)
		Poll.pollRequest({
			request: retrieveUserTransactionRequest,
			params: {
				id: trxId
			},
			conditionKey1: 'trxState',
			conditionValuesSuccess: conditionValuesSuccess,
			conditionValuesFailure: ['FAILED'],
			interval: 2000,
			iterations: 60,
			onSuccess: (response) => {
				console.log(response)
				onPollWiCodeTransactionStatusSuccess({ response: response })
			},
			onFailure: () => setReceiptState('ERROR')
		})
	}

	const onPollWiCodeTransactionStatusSuccess = ({ response }) => {
		console.log(response)
		const isPollingWiCodeSettlementState = response.trxState !== 'SETTLEMENT_SUCCESSFUL'

		setReceiptData(response)

		if (isPollingWiCodeSettlementState) {
			pollWiCodeTransactionStatus({
				trxId: response,
				conditionValuesSuccess: ['SETTLEMENT_SUCCESSFUL']
			})
			setReceiptState('PROCESSING')
		}

		paymentUpdate({
			dataReceipt: {
				trxId: response.trxId,
				storeName: response.storeName,
				basketAmount: response.basketAmount,
				totalDiscountAmount: response.totalDiscountAmount,
				totalPaymentApproved: response.totalPaymentApproved,
				payments: response.payments,
				earnings: response.earnings,
				isPollingWiCodeSettlementState
			}
		})
		// call userTransactionRequest with a delay
		setReceiptState('SUCCESS')
		console.log(payment)
		console.log(receiptData)
	}

	return (
		<div className="background" style={{height: '100vh'}}>
			<Header
				alt="back"
				headerTitle="My Rewards"
				iconLeft={Images.arrowBackWhite}
				isBackIcon={true}
				route="/myRewards"
			/>
			<div style={{ marginTop: 50 }}>
				{retrieveWiCodeV2.fetching ? <Spinner /> : <WiCode qr={wiQr} qrString={wiCode} title="Redeem Rewards" />}
			</div>
			<TransactionState
				isVisibleReceipt={receiptState !== null}
				state={receiptState}
				onPressCancel={() => navigate('/home')}
				onPressOk={() => navigate('/home')}
				onPressRetry={() => setReceiptState(null)}
			/>
		</div>
	)
}

const mapStateToProps = (state) => ({
	coupons: state.container.coupons.coupons,
	payment: state.container.payment,
	retrieveWiCodeV2: state.network.retrieveWiCodeV2
})

const mapDispatchToProps = (dispatch) => ({
	paymentUpdate: (payload) => dispatch(ContainerActions.paymentUpdate(payload)),
	retrieveUserTransactionRequest: (payload) => dispatch(NetworkActions.retrieveUserTransactionRequest(payload)),
	retrieveWiCodeRequest: (payload) => dispatch(NetworkActions.retrieveWiCodeV2Request(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ScanMyRewards)
