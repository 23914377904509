import * as Types from '../Types'

export const userUpdate = (payload) => ({
	type: Types.USER_UPDATE,
	payload
})

export const userClear = (payload) => ({
	type: Types.USER_CLEAR,
	payload
})
