/*eslint-disable*/
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Images from '../../assets'
import { Header, InboxItem, InboxMessageModal, EmptyListState } from '../../components'
import { ContainerActions, NetworkActions, PersistedActions } from '../../state'

const Inbox = ({
	getMessagesRequest,
	listsUpdate,
	inbox: { messages },
	patchMessageStateRequest,
	inboxUpdate,
	selectedMessage
}) => {
	const [isMessageDetailsVisible, setIsMessageDetailsVisible] = useState(false)

	useEffect(async () => {
		await getMessages()
	}, [])

	const getMessages = async () => {
		try {
			const response = await getMessagesRequest()
			listsUpdate({
				inbox: {
					messages: response.messages,
					totalUnreadMessages: response.overview.totalUnreadMessages
				}
			})
		} catch (e) {}
	}

	const patchMessageState = async ({ messageId }) => {
		try {
			await patchMessageStateRequest({
				id: messageId,
				state: 'READ'
			})
		} catch (e) {}
	}

	const renderMessages = () => {
		let messageList

		if (!_.isEmpty(messages)) {
			messageList = messages.map((message, index) => {
				return (
					<InboxItem
						isRead={message.state === 'READ'}
						body={message.body}
						date={moment(message.publishedDateTime).format('DD/MM/YYYY')}
						key={index}
						messageTitle={message.title}
						onClickItem={async () => {
							if (message.state === 'UNREAD') {
								await patchMessageState({ messageId: message.id })
							}
							inboxUpdate({
								selectedMessage: !_.isEmpty(message) ? message : {}
							})
							setIsMessageDetailsVisible(!isMessageDetailsVisible)
						}}
					/>
				)
			})
		}
		else {
			messageList = <EmptyListState caption='You have no messages from us.' title="NO MESSAGES YET..."/>
		}

		return messageList
	}

	return (
		<div style={{ backgroundColor: '#231F20', height: '100vh' }}>
			<Header headerTitle="Inbox" iconLeft={null} route="/home" iconRight={Images.menu}/>
			{renderMessages()}
			<InboxMessageModal
				isVisibleMessageDetails={isMessageDetailsVisible}
				messageBody={selectedMessage.body}
				messageTitle={selectedMessage.title}
				onPressButton={() => setIsMessageDetailsVisible(false)}
			/>
		</div>
	)
}

const mapStateToProps = (state) => ({
	selectedMessage: state.container.inbox.selectedMessage,
	inbox: state.persisted.lists.inbox
})

const mapDispatchToProps = (dispatch) => ({
	inboxUpdate: (payload) => dispatch(ContainerActions.inboxUpdate(payload)),
	listsUpdate: (payload) => dispatch(PersistedActions.listsUpdate(payload)),
	getMessagesRequest: (payload) => dispatch(NetworkActions.getMessagesRequest(payload)),
	patchMessageStateRequest: (payload) => dispatch(NetworkActions.patchMessageStateRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Inbox)
