/*eslint-disable*/
import _ from 'lodash'
import React, { useState } from 'react'

import Images from '../../assets'
import { convertDistance } from '../../utils'
import Header from '../header'
import Spinner from '../spinner'
import StoreListItem from '../storeListItem'

const ParticipatingStoresModal = ({ isFetching, stores, onPressClose }) => {
	const [searchTerm, setIsSearchTerm] = useState(null)

	const keysToSearch = ['storeName', 'address']

	const onSearch = (e) => setIsSearchTerm(e.target.value)

	const filterData = () => {
		if (searchTerm) {
			const filteredData = []

			stores.filter((item) =>
				keysToSearch.map((keyToSearch) => {
					if (item[keyToSearch].toLowerCase().includes(searchTerm.toLowerCase())) filteredData.push(item)
					return null
				})
			)

			return _.uniq(filteredData)
		}

		return stores
	}

	const renderStoresList = () => {
		let storeList
		if (!_.isEmpty(stores)) {
			storeList = filterData().map((store, index) => (
				<StoreListItem
					distance={convertDistance(store.distance)}
					isParticipatingStore={true}
					key={store.id || index}
					storeName={store.storeName}
					onClickItem={() => null}
				/>
			))
		}

		return storeList
	}

	return (

		<div style={{ backgroundColor: '#272727', height: '100vh' }}>
					<Header headerTitle="Participating Stores" iconLeft={Images.iconCloseWhite} route="/myRewards" onPressIcon={onPressClose}/>
					<div className="search-container">
						<input
							className="search-bar"
							name="search"
							placeholder="Search for store"
							type="text"
							onChange={(text) => onSearch(text)}
						/>
						<button type="submit" onSubmit={() => filterData()}>
							<i className="fa fa-search" />
						</button>
					</div>
					<div style={{paddingLeft: 24, marginTop: 40 }}>
						{isFetching ? <Spinner /> : renderStoresList()}
					</div>

				</div>
	)
}

export default ParticipatingStoresModal
