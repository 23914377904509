/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { VIEW_LOYALTY_CAMPAIGNS_FAILURE, VIEW_LOYALTY_CAMPAIGNS_REQUEST, VIEW_LOYALTY_CAMPAIGNS_SUCCESS } from './Types'

export const viewLoyaltyCampaignsSuccess = (payload) => ({
	type: VIEW_LOYALTY_CAMPAIGNS_SUCCESS,
	payload
})

export const viewLoyaltyCampaignsFailure = (payload) => ({
	type: VIEW_LOYALTY_CAMPAIGNS_FAILURE,
	payload
})

// Get loyalty campaigns
export const viewLoyaltyCampaignsRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: VIEW_LOYALTY_CAMPAIGNS_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.pageSize) queryString += `pageSize=${params.pageSize}&`

			return handleRequest({
				actionSuccess: viewLoyaltyCampaignsSuccess,
				actionFailure: viewLoyaltyCampaignsFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/loyalty/campaigns${queryString}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					...config.requestConfig
				}
			})
		}
