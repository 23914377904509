import React from 'react'
import { connect } from 'react-redux'

import { DrawerTab } from '../../components'
import { PersistedActions } from '../../state'

const DrawerNavigation = ({ profile, userClear, loyaltyBalance }) => {
	const links = [
		{
			id: 0,
			title: 'About',
			route: '#about'
		},
		{
			id: 1,
			title: 'How It Works',
			route: '#info'
		},
		{
			id: 2,
			title: 'Articles',
			route: 'articles/'
		},
		{
			id: 3,
			title: 'F.A.Q',
			route: 'faq/'
		},
		{
			id: 4,
			title: 'Contacts',
			route: 'contact/'
		},
		{
			id: 5,
			isLogout: true,
			title: 'Log out',
			onClick: userClear
		}
	]

	return (
		<DrawerTab
			currentBalance={loyaltyBalance}
			firstName={profile.firstName}
			lastName={profile.lastName}
			links={links}
			totalBalance={6}
		/>
	)
}

const mapStateToProps = (state) => ({
	profile: state.persisted.user.profile,
	loyaltyBalance: state.persisted.user.loyaltyBalance
})

const mapDispatchToProps = (dispatch) => ({
	userClear: (payload) => dispatch(PersistedActions.userClear(payload)),
	listsClear: (payload) => dispatch(PersistedActions.listsClear(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawerNavigation)
