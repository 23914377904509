/* eslint-disable */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

import Images from '../../../assets'
import { Button, Header, Input, Radio, Checkbox, DateInput, MobileInput, AuthError } from '../../../components'
import { ContainerActions, GeneralActions, NetworkActions, PersistedActions } from '../../../state/'

const Register = ({
	auth: { subdivisions, registerDetails },
	authError,
	authErrorShow,
	authUpdate,
	putUserMetadataRequest,
	registerUserRequest,
	findSubdivisionRequest,
	resendOtpRequest,
	userUpdate
}) => {
	const [formData, setFormData] = React.useState({
		firstName: '',
		surname: '',
		mobile: '',
		email: '',
		province: '',
		cardNo: '',
		gender: '',
		company: ''
	})

	React.useEffect(async () => {
		await findSubdivisions()
	}, [])

	const mobilePrefix = '+27'

	let formattedDate = null

	let formattedMobileNumber = null

	let userMetadata = {}

	const navigate = useNavigate()

	const { firstName, surname, mobile, email, province, cardNo, gender, company } = formData

	const [isGenderMale, setIsGenderMale] = React.useState(false)
	const [isGenderFemale, setIsGenderFemale] = React.useState(false)
	const [isGenderNotSpecified, setIsGenderNotSpecified] = React.useState(false)
	const [isOffersConsented, setIsOffersConsented] = React.useState(false)
	const [hasAgreedToTerms, setHasAgreedToTerms] = React.useState(false)
	const [isRegionsDropDown, setIsRegionsDropDown] = React.useState(false)
	const [isMobileFormActive, setIsMobileFormActive] = React.useState(false)
	const [dateOfBirth, setDateOfBirth] = React.useState("")

	const buttonDisabled = !firstName || !surname || !cardNo || !company || !hasAgreedToTerms

	const onChange = (e) =>
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})

	const findSubdivisions = async () => {
		try {
			const response = await findSubdivisionRequest()
			authUpdate({
				subdivisions: response.subdivisions
			})
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	const resendOtp = async ({ mobile }) => {
		try {
			await resendOtpRequest({
				mobile: mobile
			})
		} catch (e) {
			authErrorShow({
				authErrorTitle: e.messageTitle,
				authErrorBody: e.messageBody
			})
		}
	}

	const registerUser = async ({ formData }) => {
		const { firstName, surname, mobile, email, province, cardNo, gender, company } = formData


		let dob = dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : ""


		if (mobile) {
			formattedMobileNumber = `${mobilePrefix}${mobile}`
		}

		if (company) {
			userMetadata = {
				company: company
			}
		}

		try {
			const response = await registerUserRequest({
				firstName: firstName ? firstName.trim() : null,
				lastName: surname ? surname.trim() : null,
				mobile: formattedMobileNumber,
				email: email ? email.trim() : "",
				dateOfBirth: dob || "",
				homeRegion: province,
				pan: cardNo,
				gender: !gender || gender === 'NS' ? "" : gender,
				notificationsOptedIn: isOffersConsented
			})
			putUserMetadata({ metadata: userMetadata, userId: response.userId })
			userUpdate({
				company: company
			})

			navigate('/createPin')
		} catch (e) {
			if (e.code === '5000054') {
				await resendOtp({ mobile: formattedMobileNumber })
				navigate('/validateMobile')
				authErrorShow({
					authErrorTitle: e.messageTitle,
					authErrorBody: e.messageBody
				})
			} else if (e.code === '5000053') {
				navigate('/createPin')
				authErrorShow({
					authErrorTitle: e.messageTitle,
					authErrorBody: e.messageBody
				})
			} else {
				authErrorShow({
					authErrorTitle: e.messageTitle,
					authErrorBody: e.messageBody
				})
			}
		}
		authUpdate({
			registerDetails: {
				...registerDetails,
				...formData,
				mobile: formattedMobileNumber,
				dateOfBirth: dob,
				homeRegion: province,
				pan: cardNo,
				gender: !gender || gender === 'NS' ? null : gender,
				notificationsOptedIn: isOffersConsented
			}
		})
	}

	const putUserMetadata = ({ metadata, userId }) => {
		if (!_.isEmpty(metadata)) {
			// Parse the metadata object
			const parsedMetadata = []
			Object.keys(metadata).forEach((metaValue) => {
				parsedMetadata.push({
					name: metaValue,
					value: metadata[metaValue]
				})
			})

			putUserMetadataRequest({
				userId: userId,
				metadata: parsedMetadata,
				config: {
					canReject: false
				}
			})
		}
	}

	return (
		<React.Fragment>
			<Header headerTitle="Sign Up" iconLeft={Images.iconCloseWhite} route="/" />
			<div style={{ marginLeft: 56, marginRight: 56 }}>
				<Input
					inputId="firstName"
					inputLabel="First Name *"
					inputName="firstName"
					inputType="text"
					isRequired={true}
					key="firstName"
					value={firstName}
					onChange={(text) => onChange(text)}
				/>
				<Input
					inputId="surname"
					inputLabel="Surname *"
					inputName="surname"
					inputType="text"
					isRequired={true}
					key="surname"
					value={surname}
					onChange={(text) => onChange(text)}
				/>
				<MobileInput
					inputId="mobile"
					inputLabel="Mobile Number *"
					inputName="mobile"
					inputType="text"
					isForProfile={false}
					isMobileFormActive={isMobileFormActive}
					key="mobile"
					prefix={mobilePrefix}
					value={mobile}
					onChange={(text) => {
						let mobileNum = text.target.value
						let zero = mobileNum.charAt(0)

						if (zero === '0') {
							mobileNum = mobileNum.slice(1)
						}

						let parseMobileNumber = mobileNum.slice(0, 9)

						setFormData({
							...formData,
							mobile: parseMobileNumber
						})
					}}
					onClick={() => setIsMobileFormActive(!isMobileFormActive)}
				/>
				<Input
					inputId="email"
					inputLabel="Email"
					inputName="email"
					inputType="email"
					key="email"
					value={email}
					onChange={(text) => onChange(text)}
				/>
				<DateInput
					dateFormat="yyyy/MM/dd"
					inputLabel="Date of birth"
					inputName="dateOfBirth"
					placeholderText="YYYY/MM/DD"
					selectedDate={dateOfBirth}
					onChange={(date) => setDateOfBirth(date)}
				/>
				<Input
					inputId="province"
					inputLabel="Province*"
					inputName="province"
					inputType="text"
					isDropdown={isRegionsDropDown}
					key="province"
					subdivisions={subdivisions}
					value={province}
					onChange={(text) => onChange(text)}
					onClick={() => setIsRegionsDropDown(!isRegionsDropDown)}
				/>
				<Input
					inputId="cardNo"
					inputLabel="MyDiesel Card Number *"
					inputName="cardNo"
					inputType="number"
					isRequired={true}
					key="cardNo"
					value={cardNo}
					onChange={(e) => {
						e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 13)
						setFormData({
							...formData,
							cardNo: e.target.value
						})
					}}
				/>
				<Input
					inputId="company"
					inputLabel="Your Employer*"
					inputName="company"
					inputType="text"
					isRequired={true}
					key="company"
					placeholder="I work for"
					value={company}
					onChange={(text) => onChange(text)}
				/>
				<p className="gender">Gender</p>
				<Radio
					imageSelected={Images.radioSelected}
					imageUnselected={Images.radioUnselected}
					key="M"
					radioLabel="Male"
					selected={isGenderMale}
					onPressIcon={() => {
						setIsGenderMale(!isGenderMale)
						setIsGenderNotSpecified(false)
						setIsGenderFemale(false)
						setFormData({
							...formData,
							gender: 'M'
						})
					}}
				/>
				<Radio
					imageSelected={Images.radioSelected}
					imageUnselected={Images.radioUnselected}
					key="F"
					radioLabel="Female"
					selected={isGenderFemale}
					onPressIcon={() => {
						setIsGenderFemale(!isGenderFemale)
						setIsGenderMale(false)
						setIsGenderNotSpecified(false)
						setFormData({
							...formData,
							gender: 'F'
						})
					}}
				/>
				<Radio
					imageSelected={Images.radioSelected}
					imageUnselected={Images.radioUnselected}
					key="NS"
					radioLabel="Not Specified"
					selected={isGenderNotSpecified}
					onPressIcon={() => {
						setIsGenderNotSpecified(!isGenderNotSpecified)
						setIsGenderMale(false)
						setIsGenderFemale(false)
						setFormData({
							...formData,
							gender: 'NS'
						})
					}}
				/>
				<p className="gender">Notifications</p>
				<Checkbox
					checkboxLabel="Keep me posted on great offers and freebies!"
					imageSelected={Images.checkboxSelected}
					imageUnselected={Images.checkboxUnselected}
					key="notifs"
					selected={isOffersConsented}
					onPressIcon={() => setIsOffersConsented(!isOffersConsented)}
				/>
				<Checkbox
					isForTermsAndConditions
					imageSelected={Images.checkboxSelected}
					imageUnselected={Images.checkboxUnselected}
					key="terms"
					selected={hasAgreedToTerms}
					onPressIcon={() => setHasAgreedToTerms(!hasAgreedToTerms)}
				/>
				<div style={{ marginBottom: 60 }}>
					<Button
						buttonDisabled={buttonDisabled}
						buttonTitle="Proceed"
						onPress={async () => {
							await registerUser({ formData: formData })
						}}
					/>
				</div>
			</div>
			<AuthError errorBody={authError.authErrorBody} errorTitle={authError.authErrorTitle} key={5} />
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	auth: state.container.auth,
	authError: state.general.authError
})

const mapDispatchToProps = (dispatch) => ({
	authErrorShow: (payload) => dispatch(GeneralActions.authErrorShow(payload)),
	authUpdate: (payload) => dispatch(ContainerActions.authUpdate(payload)),
	findSubdivisionRequest: (payload) => dispatch(NetworkActions.findSubdivisionsRequest(payload)),
	putUserMetadataRequest: (payload) => dispatch(NetworkActions.putUserMetadataRequest(payload)),
	registerUserRequest: (payload) => dispatch(NetworkActions.registerV2Request(payload)),
	resendOtpRequest: (payload) => dispatch(NetworkActions.resendOtpRequest(payload)),
	userUpdate: (payload) => dispatch(PersistedActions.userUpdate(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
