/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { RESET_PIN_FAILURE, RESET_PIN_REQUEST, RESET_PIN_SUCCESS } from './Types'

export const resetPinSuccess = (payload) => ({
	type: RESET_PIN_SUCCESS,
	payload
})

export const resetPinFailure = (payload) => ({
	type: RESET_PIN_FAILURE,
	payload
})

// Reset users PIN - They are not logged in. Forgot their PIN
export const resetPinRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: RESET_PIN_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: resetPinSuccess,
				actionFailure: resetPinFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'put',
					url: '/login/reset',
					data: {
						mobile: params.mobile,
						otp: params.otp,
						pin: params.pin,
						confirmPin: params.confirmPin
					},
					...config.requestConfig
				}
			})
		}
