/*eslint-disable*/
import React, {useEffect, useRef} from 'react'

const Pin = ({ imagePinActive, imagePinInactive, pinLength, onEndReached }) => {
	const [pin, setPin] = React.useState('')
	const inputElement = useRef(null)

	useEffect(() => {
		if(inputElement.current) {
			inputElement.current.focus()
		}
	})

	const clearInput = () => {
		setPin('')
	}

	const onTypePin = ({ pin }) => {
		setPin(pin.target.value)

		if (pin.target.value.length === pinLength) {
			setTimeout(() => {
				onEndReached({ pin })
			}, 150)
		}
	}

	const renderIcons = () => {
		const iconsArray = []

		for (let i = 0; i < pinLength; i += 1) {
			if (imagePinActive) {
				iconsArray.push(
					// eslint-disable-next-line react/jsx-no-literals
					<img
						alt="pin"
						height={45}
						key={i}
						src={pin.length > i ? imagePinActive : imagePinInactive}
						style={{ marginRight: 16, marginTop: 28 }}
						width={45}
					/>
				)
			}
		}
		return iconsArray
	}

	return (
		<div style={{ height: 100 }}>
			{renderIcons()}
			<input
				autoFocus
				type='text'
				inputMode="numeric"
				maxLength={pinLength}
				style={{ opacity: 0, height: 300, width: '100%'}}
				value={pin}
				onChange={(text) => onTypePin({ pin: text })}
			/>
		</div>
	)
}

export default Pin
