export const contentContainer = {
	backgroundColor: 'white',
	width: '80%',
	borderRadius: 8,
	margin: 'auto',
	top: 150,
	left: 0,
	right: 0,
	position: 'absolute'
}
