import * as Types from '../Types'

const INITIAL_STATE = {
	authX: null,
	company: null,
	isAuthenticated: false,
	loyaltyBalance: null,
	profile: {},
	userRewardsEarned: null,
	settleWithCard: false,
	showDrawer: false,
	userState: null
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case Types.USER_UPDATE:
			return {
				...state,
				authX: action.payload.authX || state.authX,
				company: action.payload.company || state.company,
				isAuthenticated:
					typeof action.payload.isAuthenticated === typeof true
						? action.payload.isAuthenticated
						: state.isAuthenticated,
				loyaltyBalance: action.payload.loyaltyBalance >= 0 ? action.payload.loyaltyBalance : state.loyaltyBalance,
				userRewardsEarned:
					action.payload.userRewardsEarned >= 0 ? action.payload.userRewardsEarned : state.userRewardsEarned,
				profile: action.payload.profile || state.profile,
				showDrawer: typeof action.payload.showDrawer === typeof true ? action.payload.showDrawer : state.showDrawer,
				settleWithCard:
					typeof action.payload.settleWithCard === typeof true ? action.payload.settleWithCard : state.settleWithCard,
				userState: action.payload.userState || state.userState
			}

		case Types.USER_CLEAR:
			return {
				...INITIAL_STATE
			}

		default:
			return state
	}
}
