/*eslint-disable*/
import React from 'react'

import Images from '../../assets'
import Checkbox from '../checkbox'

const NotificationsBanner = ({ title, isCheckboxSelected, toggleCheckbox }) => (
	<div style={{ background: 'rgba(255, 255, 255, 0.1)', height: 71, padding: 0, width: '100%', marginTop: 9 }}>
		<div style={{ fontWeight: 'bold', fontSize: 11, color: 'white', textAlign: 'left', marginBottom: 6, paddingTop: 9 }}>{title}</div>
		<Checkbox
			checkboxLabel="Keep me posted on great offers and freebies!"
			imageSelected={Images.checkboxSelectedWhite}
			imageUnselected={Images.checkboxUnselected}
			selected={isCheckboxSelected}
			onPressIcon={toggleCheckbox}
		/>
	</div>
)

export default NotificationsBanner
