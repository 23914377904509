/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { VIEW_LOYALTY_REWARDS_FAILURE, VIEW_LOYALTY_REWARDS_REQUEST, VIEW_LOYALTY_REWARDS_SUCCESS } from './Types'

export const viewLoyaltyRewardsSuccess = (payload) => ({
	type: VIEW_LOYALTY_REWARDS_SUCCESS,
	payload
})

export const viewLoyaltyRewardsFailure = (payload) => ({
	type: VIEW_LOYALTY_REWARDS_FAILURE,
	payload
})

// Get User loyalty coupons and vouchers
export const viewLoyaltyRewardsRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: VIEW_LOYALTY_REWARDS_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.regionId) queryString += `regionId=${params.regionId}&`
			if (params.storeId) queryString += `storeId=${params.storeId}&`
			if (params.longitude) queryString += `longitude=${params.longitude}&`
			if (params.latitude) queryString += `latitude=${params.latitude}&`
			if (params.pageOffset) queryString += `pageOffset=${params.pageOffset}&`
			if (params.pageSize) queryString += `pageSize=${params.pageSize}&`
			if (params.orderBy) queryString += `orderBy=${params.orderBy}&`
			if (params.orderDirection) queryString += `orderDirection=${params.orderDirection}&`
			queryString = queryString.slice(0, -1)

			return handleRequest({
				actionSuccess: viewLoyaltyRewardsSuccess,
				actionFailure: viewLoyaltyRewardsFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/loyalty/coupons${queryString}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					...config.requestConfig
				}
			})
		}
