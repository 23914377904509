/*eslint-disable*/
import React from 'react'
import { connect } from 'react-redux'

import Images from '../../assets'
import { GeneralActions } from '../../state'

const AuthError = ({ authError: { isErrorVisible }, authErrorClear, errorTitle, errorBody, onClose }) =>
	isErrorVisible && (
		<div style={{position: 'fixed', bottom: 10, left: 0, right: 0, width: '80%', margin: 'auto', background: 'rgba(255, 255, 255, 0.1)',height: 100, padding: 12 }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ fontWeight: 'bold', fontSize: 13, color: 'white', textAlign: 'left' }}>{errorTitle}</div>
				<img
					alt="close"
                    width={10}
                    height={10}
					src={Images.iconCloseWhite}
					onClick={() => {
                        onClose && onClose()
                        authErrorClear()
                    }
                }
				/>
			</div>
			<div style={{ fontSize: 13, color: 'white', textAlign: 'left', marginTop: 12 }}>{errorBody}</div>
		</div>
	)

const mapStateToProps = (state) => ({
	authError: state.general.authError
})

const mapDispatchToProps = (dispatch) => ({
	authErrorClear: (payload) => dispatch(GeneralActions.authErrorClear(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthError)
