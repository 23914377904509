/*eslint-disable*/
import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import Badge from "../badge"
import {connect} from 'react-redux'
import {NetworkActions, PersistedActions} from "../../state";

const BottomTab = ({ tabs, inbox: {totalUnreadMessages}, listsUpdate,getMessagesRequest  }) => {

    useEffect(async () => {
       await getMessages()
    }, [])

    const getMessages = async () => {
        try {
            const response = await getMessagesRequest()

            listsUpdate({
                inbox: {
                    totalUnreadMessages: response.overview.totalUnreadMessages
                }
            })
        } catch (e) {}
    }

    const [isActiveIndex, setIsActiveIndex] = useState(0)

    return (<div style={{
        height: 50,
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        paddingTop: 10,
        position: 'fixed',
        bottom: 0,
    }}>
        {tabs.map((tab, index) => (
            <div key={index} onClick={() => setIsActiveIndex(tab.id)}>
                <Link key={index} to={tab.route}>
                    <div>
                        {tab.label === 'INBOX' && totalUnreadMessages > 0 && <Badge unreadMessages={totalUnreadMessages}/>}
                        <img alt="tab-icon" src={isActiveIndex === tab.id ? tab.iconActive : tab.icon} width={24} height={24}/>
                    </div>
                </Link>
                <div style={{color: isActiveIndex === tab.id ? '#231F20' : 'rgba(82, 82, 82, 0.5)', fontSize: 10, fontWeight: isActiveIndex === tab.id ? 'bold' : 'normal'}}>{tab.label}</div>
            </div>

        ))}
    </div>)
}

const mapStateToProps = (state) => ({
  inbox: state.persisted.lists.inbox
})

const mapDispatchToProps = (dispatch) => ({
    listsUpdate: (payload) => dispatch(PersistedActions.listsUpdate(payload)),
    getMessagesRequest: (payload) => dispatch(NetworkActions.getMessagesRequest(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BottomTab)
