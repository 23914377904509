/*eslint-disable*/
import React from 'react'

import Button from '../button'
import * as Styles from './InlineStyles'

const UserUpdatedModal = ({ isVisibleModalDetails, onPressButton, messageTitle, messageBody }) => {
	messageTitle = 'Changes have been made to\n' + 'your profile.'

	messageBody = 'You have updated you details, Didn\'t make these changes? Contact support.'

    return (
		isVisibleModalDetails && (
			<div className="transaction-details-modal" onClick={onPressButton}>
				<div style={Styles.contentContainer}>
					<div style={{ marginTop: 40, fontSize: 16, color: '#525252', fontWeight: 'bold', marginBottom: 12 }}>
						{messageTitle}
					</div>
					<p style={{ fontSize: 12, color: '#525252', margin: 8 }}>{messageBody}</p>
					<div style={{paddingBottom: 60}}>
						<Button isForModal buttonTitle="Okay" className="alert-button" buttonClassName='alert-button-text' onPress={onPressButton} />
					</div>
				</div>
			</div>
		)
	)
}

export default UserUpdatedModal
