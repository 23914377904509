/*eslint-disable*/
import React from 'react'

import Button from '../button'
import * as Styles from '../detailsModal/InlineStyles'

const InboxMessageModal = ({ isVisibleMessageDetails, onPressButton, messageTitle, messageBody }) =>
	isVisibleMessageDetails && (
		<div className="transaction-details-modal" onClick={onPressButton}>
			<div style={Styles.detailsContainer}>
				<div style={{marginTop: 40, fontSize: 16, color: '#525252', fontWeight: 'bold', marginBottom: 12 }}>{messageTitle}</div>
				<p style={{fontSize: 12, color: '#525252', margin: 8}}>{messageBody}</p>
				<div style={{marginBottom: 41}}>
					<Button isForModal buttonTitle="Okay" className="alert-button" buttonClassName='alert-button-text' onPress={onPressButton} />
				</div>
			</div>
		</div>
	)

export default InboxMessageModal
