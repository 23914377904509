/*eslint-disable*/
import React from 'react'
import './Styles.css'

const Toggle = ({ leftTitle, rightTitle, isLeftActive = true, isRightActive, onClickLeft, onClickRight }) => (
	<div className="container" style={{ backgroundColor: '#231F20'}}>
		<div
			onClick={onClickLeft}
			className="toggle-item"
			style={{
				backgroundColor: isLeftActive ? 'white' : 'transparent',
				borderColor: isLeftActive ? 'transparent' : 'white'
			}}
		>
			<p className="text" style={{ color: isLeftActive ? 'black' : 'white' }}>
				{leftTitle}
			</p>
		</div>
		<div
			onClick={onClickRight}
			className="toggle-item"
			style={{
				backgroundColor: isRightActive ? 'white' : 'transparent',
				borderColor: isRightActive ? 'transparent' : 'white'
			}}
		>
			<p className="text" style={{ color: isRightActive ? 'black' : 'white' }}>
				{rightTitle}
			</p>
		</div>
	</div>
)

export default Toggle
