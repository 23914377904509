import * as Types from '../Types'

export const couponsUpdate = (payload) => ({
	type: Types.COUPONS_UPDATE,
	payload
})

export const couponsClear = (payload) => ({
	type: Types.COUPONS_CLEAR,
	payload
})
