import * as Types from '../Types'

export const inboxUpdate = (payload) => ({
	type: Types.INBOX_UPDATE,
	payload
})

export const inboxClear = (payload) => ({
	type: Types.INBOX_CLEAR,
	payload
})
