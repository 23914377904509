/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { RESEND_OTP_FAILURE, RESEND_OTP_REQUEST, RESEND_OTP_SUCCESS } from './Types'

export const resendOtpSuccess = (payload) => ({
	type: RESEND_OTP_SUCCESS,
	payload
})

export const resendOtpFailure = (payload) => ({
	type: RESEND_OTP_FAILURE,
	payload
})

// Resends OTP for registration verification
export const resendOtpRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: RESEND_OTP_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: resendOtpSuccess,
				actionFailure: resendOtpFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'post',
					url: '/user/register/otp',
					data: {
						mobile: params.mobile,
						email: params.email
					},
					...config.requestConfig
				}
			})
		}
