/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { GET_STORE_FAILURE, GET_STORE_REQUEST, GET_STORE_SUCCESS } from './Types'

export const getStoreSuccess = (payload) => ({
	type: GET_STORE_SUCCESS,
	payload
})

export const getStoreFailure = (payload) => ({
	type: GET_STORE_FAILURE,
	payload
})

// Find store
export const getStoreRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: GET_STORE_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.orderProcessing) queryString += `orderProcessing=${params.orderProcessing}&`
			if (params.checkOnlineStatus) queryString += `checkOnlineStatus=${params.checkOnlineStatus}`
			queryString = queryString.slice(0, -1)

			return handleRequest({
				actionSuccess: getStoreSuccess,
				actionFailure: getStoreFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/stores/${params.storeId}${queryString}`,
					...config.requestConfig
				}
			})
		}
