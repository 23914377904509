import Environment from './Env'

const debugMode = {
	isDebugMode: true // DISABLE THIS FOR PRODUCTION/STORES/UAT
}

const state = {
	apiKey: Environment.apiKey,
	baseUrl: Environment.baseUrl,
	contentfulVspId: Environment.contentfulVspId,
	googleMapURL: Environment.googleMapURL,
	debugMode
}

export default {
	state
}
