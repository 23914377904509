/*eslint-disable*/
import React from 'react'

import Images from '../../assets'
import Button from '../button'
import * as Styles from '../errorModal/InlineStyles'
import {useNavigate} from 'react-router-dom'


const title = 'Congratulations!'

const messageBody =
	'You’ve reached 6 visits which means you’ve unlocked a surprise reward. Please navigate to your rewards section and claim it.'

const RewardsModal = ({ onPressButton, route }) => {
	const navigate = useNavigate()
	return (<div className="transaction-details-modal">
			<div style={Styles.detailsContainer}>
				<img alt="congrats" src={Images.congrats} style={{width: 93, height: 90, marginTop: 44}} />
				<div style={{ fontWeight: '900', fontSize: 20, color: '#525252', marginTop: 32 }}>{title}</div>
				<div
					style={{ fontSize: 12, fontWeight: 400, marginTop: 12, marginRight: 18, marginLeft: 18, color: '#525252' }}
				>
					{messageBody}
				</div>
				<div style={{ marginBottom: 36 }}>
					<Button
						isForModal
						buttonClassName="alert-button-text"
						buttonDisabled={false}
						buttonTitle="Okay"
						className="alert-button"
						onPress={() => {
							onPressButton()
							navigate(route)
						}}
					/>
				</div>
			</div>
		</div>
	)
}


export default RewardsModal
