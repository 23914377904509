/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { PUT_USER_METADATA_FAILURE, PUT_USER_METADATA_REQUEST, PUT_USER_METADATA_SUCCESS } from './Types'

export const putUserMetadataSuccess = (payload) => ({
	type: PUT_USER_METADATA_SUCCESS,
	payload
})

export const putUserMetadataFailure = (payload) => ({
	type: PUT_USER_METADATA_FAILURE,
	payload
})

// Put user metadata
export const putUserMetadataRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: PUT_USER_METADATA_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: putUserMetadataSuccess,
				actionFailure: putUserMetadataFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'put',
					url: `/user/profile/${params.userId}/customfields`,
					data: {
						metadata: params.metadata
					},
					...config.requestConfig
				}
			})
		}
