/*eslint-disable*/
import React from 'react'

import Images from '../../assets'
import Button from '../button'
import './Styles.css'
import * as Styles from './InlineStyles'

const amountTitle = 'Amount'

const rewardRedeemedTitle = 'Reward Redeemed'

const rewardEarnedTitle = 'Reward Earned'

const DetailsModal = ({ storeName, transactionDate, amountRedeemed, reward, onPressButton, isVisibleDetails, pointsEarned }) =>
	isVisibleDetails && (
		<div className="transaction-details-modal" onClick={onPressButton}>
			<div style={Styles.detailsContainer}>
				<img alt="logo" src={Images.blueLogo} />
				<div style={Styles.storeName}>{storeName}</div>
				<div style={Styles.date}>{transactionDate}</div>
				<div style={Styles.transactionInfo}>
					<div style={Styles.amountTitle}>{amountTitle}</div>
					<div style={Styles.amountRedeemed}>{amountRedeemed}</div>
				</div>
				<div style={Styles.rewardsInfo}>
					<div style={Styles.rewardRedeemedTitle}>
						{rewardRedeemedTitle}
					</div>
					<div style={Styles.reward}>{reward}</div>
				</div>
				{pointsEarned && <div style={Styles.rewardsInfo}>
					<div style={Styles.rewardRedeemedTitle}>
						{rewardEarnedTitle}
					</div>
					<div style={Styles.reward}>{`${pointsEarned} Visit`}</div>
				</div>}
				<div style={{marginBottom: 36}}>
					<Button isForModal buttonTitle="Okay" className="alert-button" buttonClassName='alert-button-text' onPress={onPressButton} />
				</div>
			</div>
		</div>
	)

export default DetailsModal
