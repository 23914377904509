/*eslint-disable*/
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Images from '../../assets/'
import './Styles.css'
import { ArticleItem, Button, Header, Article, Spinner, RewardsModal } from '../../components'
import Config from '../../config/AppConfiguration'
import { ContainerActions, GeneralActions, NetworkActions, PersistedActions } from '../../state'
import Poll from '../../utils/Poll'

class Home extends Component {
	constructor(props) {
		super(props)

		this.state = {
			articles: [],
			displayRewardsBadge: false,
			isArticleDetailsVisible: false,
			selectedArticle: {},
			gaugePoints: 0,
			isVisibleRewardsModal: false,
			totalRewardsEarned: null,
			latitude: null,
			longitude: null,
			couponsLength: 0
		}
	}

	async componentDidMount() {
		const { authErrorClear } = this.props

		authErrorClear() // clear any auth error banners
		Poll.pollCancel()
		await this.viewLoyaltyCampaigns()
		await this.getArticles()
		navigator.geolocation.getCurrentPosition(async (position) => {
			await this.setState({ latitude: position.coords.latitude })
			await this.setState({ longitude: position.coords.longitude })
		})
		this.getAllUserCoupons()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { userRewardsEarned } = this.props
		const {couponsLength} = this.state

		if (prevProps.userRewardsEarned !== null && userRewardsEarned > prevProps.userRewardsEarned) {
			this.setState({
				isVisibleRewardsModal: true
			})
		}

		if(couponsLength > prevState.couponsLength){
			this.setState({
				displayRewardsBadge: true
			})
		}
	}

	getArticles = async () => {
		const { getArticlesRequest, errorShow } = this.props
		try {
			const response = await getArticlesRequest({
				config: {
					canReject: true,
					canShowError: true
				}
			})

			if (response && !_.isEmpty(response.items)) {
				this.setState({ articles: response.items[0].fields.articleTemplates })
			}
		} catch (e) {
			errorShow({
				errorTitle: 'Something went wrong',
				errorBody: 'Error retrieving contentful'
			})
		}
	}

	getAllUserCoupons = async () => {
		const {
			couponsUpdate,
			getLoyaltyRewardsRequest,
			getPublicCouponCampaignsRequest,
			getUserPersonalisedVouchersRequest,
			coupons: {coupons}
		} = this.props

		const { latitude, longitude } = this.state

		try {
			const parallelApiResponse = [
				await getLoyaltyRewardsRequest(),
				await getPublicCouponCampaignsRequest({
					lon: longitude,
					lat: latitude
				}),
				await getUserPersonalisedVouchersRequest()
			]

			const [loyaltyRewards, publicCouponCampaigns, userPersonalisedVouchers] = parallelApiResponse
			const { couponVoucherList = [] } = loyaltyRewards
			const { couponCampaigns = [] } = publicCouponCampaigns
			const { coupons = [] } = userPersonalisedVouchers

			couponsUpdate({
				coupons: [...couponVoucherList, ...couponCampaigns, ...coupons]
			})
		} catch (e) {
			console.log(e)
		}
		this.setState({couponsLength: coupons.length})
	}

	viewLoyaltyCampaigns = async () => {
		const { viewLoyaltyCampaignsRequest, userUpdate, errorShow } = this.props

		const {
			debugMode: { isDebugMode }
		} = Config.state
		let spendLoyaltyCampaign
		let rewardsEarnedOnCampaign
		try {
			const response = await viewLoyaltyCampaignsRequest({
				pageSize: 100,
				config: {
					canReject: false,
					canShowError: true
				}
			})
			// console.log(response)
			if (!_.isEmpty(response?.campaigns)) {
				response.campaigns.forEach((campaign) => {
					const { rewardsEarned } = campaign

					if (isDebugMode ? campaign.id === 739 : campaign.id === 1222) {
						spendLoyaltyCampaign = campaign
						rewardsEarnedOnCampaign = rewardsEarned
					}
				})
			}
			this.setState({ gaugePoints: spendLoyaltyCampaign.currentBalance })
			userUpdate({
				loyaltyBalance: spendLoyaltyCampaign.currentBalance,
				userRewardsEarned: rewardsEarnedOnCampaign
			})
		} catch (e) {
			// console.log(e)
			errorShow({
				errorTitle: 'Something went wrong',
				errorBody: 'Error retrieving your loyalty campaigns'
			})
		}
	}

	renderRewardsModal = () => (
		<RewardsModal
			key={2}
			route="/myRewards"
			onPressButton={() => {
				this.setState({
					isVisibleRewardsModal: false
				})
			}}
		/>
	)

	renderArticles = () => {
		const { articles, isArticleDetailsVisible } = this.state

		const listItems = articles?.map((article, index) => {
			return (
				<ArticleItem
					articleImage={`https:${article.fields.headerImage.fields.file.url}`}
					key={index}
					title={article.fields.header}
					onClickArticle={() => {
						this.setState({
							selectedArticle: !_.isEmpty(article) ? article : {},
							isArticleDetailsVisible: !isArticleDetailsVisible
						})
					}}
				/>
			)
		})

		return <div style={{ paddingBottom: 80, paddingTop: 60 }}>{listItems}</div>
	}

	render() {
		const {
			profile,
			viewLoyaltyCampaigns: { fetching }
		} = this.props

		const { displayRewardsBadge, gaugePoints, selectedArticle, isArticleDetailsVisible, isVisibleRewardsModal } = this.state

		return [
			<div className="background" key={1}>
				<Header iconRight={Images.menu} route="/home" />
				<img
					alt="logo"
					className="logo"
					height={120}
					src={Images.homeLogo}
					style={{ position: 'absolute', top: 0, right: 0, left: 0, margin: 'auto' }}
					width={266}
				/>
				<div className="user-info-container">
					<h3 className="user-greeting">Hello {profile.firstName}!</h3>
					{fetching ? (
						<Spinner />
					) : (
						<div className="img-wrap">
							<img alt="meter" height={182} src={Images.homeFuelMeter} width={259} />
							<img
								alt="pointer"
								className={'pointer'}
								src={Images.homePointer}
								style={{
									position: 'absolute',
									top: 110,
									left: 55,
									zIndex: 1,
									animation: `spin${gaugePoints} 2s linear forwards`
								}}
								width={100}
							/>
						</div>
					)}
					<div className="user-balance">{fetching ? 'Calculating...' : 6 - gaugePoints} VISITS TO GO</div>
					<div className="user-balance-caption" style={{ fontSize: 13, fontWeight: 'normal' }}>
						{' '}
						Spend R600 or more on fuel and get{' '}
					</div>
					<div className="user-balance-caption" style={{ fontSize: 13, fontWeight: 'normal' }}>
						rewarded after 6 visits.
					</div>
				</div>
				<div className="button-container" style={{ height: 0 }}>
					<Link to="/myRewards">
						<div style={{ width: 161 }}>
							<Button
								buttonStyles={{ marginTop: 16, marginBottom: 16 }}
								buttonTitle="My Rewards"
								buttonWidth={161}
								hasNewCoupon={displayRewardsBadge}
								onPress={() => this.setState({
									displayRewardsBadge: false
								})}
							/>
						</div>
					</Link>
					<Link to="/scanEarn">
						<div style={{ width: 161, paddingLeft: 21 }}>
							<Button
								buttonStyles={{ marginTop: 16, marginBottom: 16 }}
								buttonTitle="Scan To Earn"
								buttonWidth={161}
								onPress={() => null}
							/>
						</div>
					</Link>
				</div>
				<div className="bonus-rewards-caption">
					<div style={{ fontWeight: '900', color: 'white', marginBottom: 8 }}>BONUS REWARDS</div>
					<div style={{ color: 'white', fontSize: 13 }}>Stand a chance to win bonus rewards</div>
					<div style={{ color: 'white', fontSize: 13 }}>everytime you fill up your vehicle.</div>
				</div>
				<p className="user-balance">Latest News</p>
				<img alt="down-arrow" className="arrow" height={9} src={Images.homeArrowDown} width={16} />
				{this.renderArticles()}
			</div>,
			<>{isVisibleRewardsModal && this.renderRewardsModal()}</>,
			<Article
				data={selectedArticle}
				isVisible={isArticleDetailsVisible}
				key={3}
				onClickBack={() => {
					this.setState({
						isVisibleArticleDetails: false,
						selectedArticle: {}
					})
				}}
			/>
		]
	}
}

const mapStateToProps = (state) => ({
	coupons: state.container.coupons,
	loyaltyBalance: state.persisted.user.loyaltyBalance,
	userRewardsEarned: state.persisted.user.userRewardsEarned,
	profile: state.persisted.user.profile,
	viewLoyaltyCampaigns: state.network.viewLoyaltyCampaigns
})

const mapDispatchToProps = (dispatch) => ({
	authErrorClear: (payload) => dispatch(GeneralActions.authErrorClear(payload)),
	couponsUpdate: (payload) => dispatch(ContainerActions.couponsUpdate(payload)),
	errorShow: (payload) => dispatch(GeneralActions.errorShow(payload)),
	getArticlesRequest: (payload) => dispatch(NetworkActions.getArticlesRequest(payload)),
	getLoyaltyRewardsRequest: (payload) => dispatch(NetworkActions.viewLoyaltyRewardsRequest(payload)),
	getPublicCouponCampaignsRequest: (payload) => dispatch(NetworkActions.viewPublicCouponCampaignsRequest(payload)),
	getUserPersonalisedVouchersRequest: (payload) =>
		dispatch(NetworkActions.viewUserpersonalisedVouchersRequest(payload)),
	userUpdate: (payload) => dispatch(PersistedActions.userUpdate(payload)),
	viewLoyaltyCampaignsRequest: (payload) => dispatch(NetworkActions.viewLoyaltyCampaignsRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
