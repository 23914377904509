/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { UPDATE_FAILURE, UPDATE_REQUEST, UPDATE_SUCCESS } from './Types'

export const updateSuccess = (payload) => ({
	type: UPDATE_SUCCESS,
	payload
})

export const updateFailure = (payload) => ({
	type: UPDATE_FAILURE,
	payload
})

// Update user details / profile
export const updateRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: UPDATE_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.sendValidationOtp) queryString += `sendValidationOtp=${params.sendValidationOtp}&`
			queryString = queryString.slice(0, -1)

			return handleRequest({
				actionSuccess: updateSuccess,
				actionFailure: updateFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'put',
					url: `/user/profile${queryString}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					data: {
						firstName: params.firstName,
						lastName: params.lastName,
						mobile: params.mobile,
						email: params.email,
						dateOfBirth: params.dateOfBirth,
						homeRegion: params.homeRegion,
						fcmToken: params.fcmToken,
						gender: params.gender,
						pan: params.pan
					},
					...config.requestConfig
				}
			})
		}
