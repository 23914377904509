let callResponse = {}
let callSuccess = null
let callIterations = 0
let int = null

const pollCancel = () => {
	clearInterval(int)
	callResponse = {}
	callSuccess = null
	callIterations = 0
	int = null
}

const pollRequest = ({
	request,
	onSuccess,
	onFailure,
	onTimeout,
	doAlways,
	params,
	conditionKey1,
	conditionKey2,
	conditionKey3,
	conditionKey4,
	conditionValuesSuccess,
	conditionValuesFailure,
	interval = 2000,
	iterations = 60
}) => {
	int = setInterval(async () => {
		try {
			const response = await request(params)
			callSuccess = true
			callResponse = response
		} catch (error) {
			callSuccess = false
		} finally {
			callIterations += 1

			let responseConditionData = null
			if (callSuccess) {
				responseConditionData = callResponse[conditionKey1]
				if (conditionKey1 && conditionKey2 && conditionKey3 && conditionKey4)
					responseConditionData = callResponse[conditionKey1][conditionKey2][conditionKey3][conditionKey4]
				else if (conditionKey1 && conditionKey2 && conditionKey3)
					responseConditionData = callResponse[conditionKey1][conditionKey2][conditionKey3]
				else if (conditionKey1 && conditionKey2) responseConditionData = callResponse[conditionKey1][conditionKey2]
			}

			const returnedResponse = callResponse
			if (doAlways) doAlways(returnedResponse)
			if (callIterations === iterations) {
				pollCancel()
				if (onTimeout) onTimeout(returnedResponse)
			} else if (callSuccess === false || conditionValuesFailure.includes(responseConditionData)) {
				pollCancel()
				if (onFailure) onFailure(returnedResponse)
			} else if (conditionValuesSuccess.includes(responseConditionData)) {
				pollCancel()
				if (onSuccess) onSuccess(returnedResponse)
			}
		}
	}, interval)
}

export default {
	pollCancel,
	pollRequest
}
