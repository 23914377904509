import * as Types from '../Types'

export const listsUpdate = (payload) => ({
	type: Types.LISTS_UPDATE,
	payload
})

export const listsClear = () => ({
	type: Types.LISTS_CLEAR
})
