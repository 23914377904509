/*eslint-disable*/
import React, { useState } from 'react'

const Input = ({
	canShowError,
	errorTitle,
	errorBody,
	selectedRegion,
	subdivisions,
	authUpdate,
	findSubdivisionRequest,
	isRequired,
	isDateOfBirth,
	isDropdown,
	inputLabel,
	inputType,
	inputId,
	inputName,
	value,
	onChange,
	onClick,
	maxLength,
	onFocus,
	...rest
}) => {
	return (
		<>
			<label htmlFor={inputName}>{inputLabel}</label>
			<div>
				{isDropdown ? (
					<select
						defaultValue={selectedRegion || null}
						id={inputId}
						name={inputName}
						placeholder={inputLabel}
						style={{ padding: 8, paddingRight: 8 }}
						value={value}
						onChange={onChange}
					>
						{subdivisions.map((subdivision) => {
							return (
								<option key={subdivision.id} value={subdivision.id}>
									{subdivision.name}
								</option>
							)
						})}
					</select>
				) : (
					<>
						<input
							id={inputId}
							key={inputId}
							maxLength={maxLength}
							name={inputName}
							// placeholder={inputLabel}
							required={isRequired}
							type={inputType}
							value={value}
							onChange={onChange}
							onClick={onClick}
							onFocus={onFocus}
							{...rest}
							style={{ padding: 8, borderColor: 'white' }}
						/>
						{canShowError && <div style={{color: '#ED596B', textAlign: 'left', marginTop: 8,fontSize: 12}}>{`${errorTitle}: ${errorBody}`}</div>}
					</>
				)}
			</div>
		</>
	)
}

export default Input
