/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { REGISTER_V2_FAILURE, REGISTER_V2_REQUEST, REGISTER_V2_SUCCESS } from './Types'

export const registerV2Success = (payload) => ({
	type: REGISTER_V2_SUCCESS,
	payload
})

export const registerV2Failure = (payload) => ({
	type: REGISTER_V2_FAILURE,
	payload
})

// Registers a new user and sends OTP for verification
export const registerV2Request =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: REGISTER_V2_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: registerV2Success,
				actionFailure: registerV2Failure,
				...config,
				dispatch,
				requestConfig: {
					method: 'post',
					url: '/user/register/v2',
					data: {
						mobile: params.mobile,
						firstName: params.firstName,
						lastName: params.lastName,
						email: params.email,
						dateOfBirth: params.dateOfBirth,
						homeRegion: params.homeRegion,
						fcmToken: params.fcmToken,
						pan: params.pan,
						gender: params.gender,
						notificationsOptedIn: params.notificationsOptedIn
					},
					...config.requestConfig
				}
			})
		}
