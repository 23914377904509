import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import sha1 from 'sha1'

import { AuthError, Header, PinState } from '../../../components'
import { ContainerActions, GeneralActions, NetworkActions } from '../../../state/'

const ForgotPinConfirmPin = ({ auth: { pinCreate }, authError, authUpdate, authErrorShow }) => {
	const navigate = useNavigate()

	const onSubmit = async ({ pin }) => {
		const pinHashed = sha1(pin.target.value)
		console.log(pinHashed)

		if (pinHashed === pinCreate) {
			authUpdate({
				pinConfirm: pinHashed
			})
			navigate('/forgotPinValidateMobile')
		} else {
			authErrorShow({
				authErrorTitle: 'Pin Mismatch',
				authErrorBody: 'Pin does not match the one you created'
			})
			navigate('/forgotPinCreatePin')
		}
	}

	return [
		// eslint-disable-next-line react/jsx-no-literals
		<Header headerTitle="Confirm PIN" iconLeft={null} key={1} route="/createPin" />,
		<div key={2} style={{ paddingLeft: 40, paddingRight: 40 }}>
			{/* eslint-disable-next-line react/jsx-no-literals */}
			<PinState state="CONFIRM_PIN" onEndReached={({ pin }) => onSubmit({ pin: pin })} />
		</div>,
		<AuthError
			errorBody={authError.authErrorBody}
			errorTitle={authError.authErrorTitle}
			key={3}
			onClose={() => navigate('/createPin')}
		/>
	]
}
const mapStateToProps = (state) => ({
	auth: state.container.auth,
	authError: state.general.authError
})

const mapDispatchToProps = (dispatch) => ({
	authErrorShow: (payload) => dispatch(GeneralActions.authErrorShow(payload)),
	authUpdate: (payload) => dispatch(ContainerActions.authUpdate(payload)),
	createPinRequest: (payload) => dispatch(NetworkActions.createPinRequest(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPinConfirmPin)
