/*eslint-disable*/
import React from 'react'
import QRCode from 'react-qr-code'
import Images from '../../assets/'

const WiCode = ({
	title,
	caption = 'Hold your device 30cm from the\n' + 'scanner and listen to the beep.',
	qr,
	qrString,
	bottomCaption = "If the  merchant doesn't have a QR\n code scanner, you can give the\n cashier the number to enter manually."
}) => (
	<div style={{ backgroundColor: 'white', width: '70%', margin: 'auto', padding: 24, borderRadius: 8 }}>
		<h2 style={{color: '#525252'}}>{title}</h2>
		<p style={{fontSize: 14, color: '#525252'}}>{caption}</p>
		<QRCode bgColor="white" fgColor="black" size={224} value={qr || qrString} />
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <img alt='yoyo-logo' src={Images.yoyoLogo} width={42} height={30} style={{ marginTop: 25}}/>
			<div style={{marginTop: 25}}>
				<div style={{textAlign: 'left', fontWeight: 'bold', fontSize: 12, color: '#525252'}}>wiCode</div>
				<div style={{fontSize: 12, fontWeight: 700, color:'#525252'}}>{qrString}</div>
			</div>
        </div>
		<p style={{fontSize: 14, color: '#525252'}}>{bottomCaption}</p>
	</div>
)

export default WiCode
