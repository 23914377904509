import * as Types from '../Types'

const initialState = {
	messageIsVisible: false,
	selectedMessage: {}
}

export default (currentState = initialState, action) => {
	const { payload: { messageIsVisible, selectedMessage } = {} } = action

	switch (action.type) {
		case Types.INBOX_UPDATE:
			return {
				...currentState,
				messageIsVisible: typeof messageIsVisible === typeof true ? messageIsVisible : currentState.messageIsVisible,
				selectedMessage: selectedMessage || currentState.selectedMessage
			}

		case Types.INBOX_CLEAR:
			return {
				...initialState
			}

		default:
			return currentState
	}
}
