/*eslint-disable*/
import React from 'react'
import { Link } from 'react-router-dom'
import './Styles.css'

import { connect } from 'react-redux'

import { PersistedActions } from '../../state'

const Header = ({
	alt,
	headerTitle,
	isBackIcon,
	iconHeight,
	iconLeft,
	iconRight,
	iconWidth,
	onPressIcon,
	route,
	showDrawer,
	userUpdate,
}) => (
	<div className="header-container" style={{ display: 'flex', height: 60, paddingLeft: 24 }}>
		{route && (
			<Link to={route}>
				{iconLeft && (
						<img
							width={isBackIcon? 5.87 : 10}
							alt={alt}
							height={10}
							src={iconLeft}
							style={{ marginTop: 24 }}
						/>
					)}
			</Link>
		)}
		<div
			className="title"
			style={{
				width: '100%',
				marginTop: 0
			}}
		>
			<h4>{headerTitle}</h4>
		</div>
		<button
				className="close"
				style={{ borderWidth: 0, display: 'flex', backgroundColor: 'transparent' }}
				onClick={() =>
					userUpdate({
						showDrawer: !showDrawer
					})
				}
			>
				{iconRight && (
					<img
						alt={alt}
						height={15}
						src={iconRight}
						style={{ marginTop: 24, resizeMode: 'contain' }}
						width={15}
					/>
				)}
			</button>
	</div>
)

const mapStateToProps = (state) => ({
	showDrawer: state.persisted.user.showDrawer
})

const mapDispatchToProps = (dispatch) => ({
	userUpdate: (payload) => dispatch(PersistedActions.userUpdate(payload))
})
export default connect(mapStateToProps, mapDispatchToProps)(Header)
