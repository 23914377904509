/*eslint-disable*/
import handleRequest from '../HandleRequest'
import {
	VIEW_TRANSACTION_HISTORY_FAILURE,
	VIEW_TRANSACTION_HISTORY_REQUEST,
	VIEW_TRANSACTION_HISTORY_SUCCESS
} from './Types'

export const viewTransactionHistorySuccess = (payload) => ({
	type: VIEW_TRANSACTION_HISTORY_SUCCESS,
	payload
})

export const viewTransactionHistoryFailure = (payload) => ({
	type: VIEW_TRANSACTION_HISTORY_FAILURE,
	payload
})

// View transaction history
export const viewTransactionHistoryRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: VIEW_TRANSACTION_HISTORY_REQUEST,
				payload: params
			})

			let queryString = '?'
			if (params.fromDate) queryString += `fromDate=${params.fromDate}&`
			queryString = queryString.slice(0, -1)

			return handleRequest({
				actionSuccess: viewTransactionHistorySuccess,
				actionFailure: viewTransactionHistoryFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/transactions${queryString}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					...config.requestConfig
				}
			})
		}
