/*eslint-disable*/
import handleRequest from '../HandleRequest'
import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS } from './Types'

export const loginSuccess = (payload) => ({
	type: LOGIN_SUCCESS,
	payload
})

export const loginFailure = (payload) => ({
	type: LOGIN_FAILURE,
	payload
})

// Logs in, authenticates user
export const loginRequest =
	(payload = {}) =>
		(dispatch) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: LOGIN_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: loginSuccess,
				actionFailure: loginFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'post',
					url: '/login',
					data: {
						pin: params.pin,
						mobile: params.mobile
					},
					...config.requestConfig
				}
			})
		}
