/*eslint-disable*/
import handleRequest from '../HandleRequest'
import {
	RETRIEVE_USER_TRANSACTION_FAILURE,
	RETRIEVE_USER_TRANSACTION_REQUEST,
	RETRIEVE_USER_TRANSACTION_SUCCESS
} from './Types'

export const retrieveUserTransactionSuccess = (payload) => ({
	type: RETRIEVE_USER_TRANSACTION_SUCCESS,
	payload
})

export const retrieveUserTransactionFailure = (payload) => ({
	type: RETRIEVE_USER_TRANSACTION_FAILURE,
	payload
})

// Poll user transaction
export const retrieveUserTransactionRequest =
	(payload = {}) =>
		(dispatch, getState) => {
			const { config = {}, ...params } = payload

			dispatch({
				type: RETRIEVE_USER_TRANSACTION_REQUEST,
				payload: params
			})

			return handleRequest({
				actionSuccess: retrieveUserTransactionSuccess,
				actionFailure: retrieveUserTransactionFailure,
				...config,
				dispatch,
				requestConfig: {
					method: 'get',
					url: `/user/transaction/${params.id}`,
					headers: {
						'Authorization-X': getState().persisted.user.authX
					},
					...config.requestConfig
				}
			})
		}
